import styled from "styled-components";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function CardMenuBtn({label, subLabel, bgImg, callBackEvent}) {
    return (
        <MenuBottom
            imageUrl={bgImg}
            onClick={(e) => callBackEvent && callBackEvent()}
        >
            <EnterButton>
                <EnterLabel>
                    <span>{label}</span>
                    <MdOutlineKeyboardArrowRight />
                </EnterLabel>
                {subLabel && <span>{subLabel}</span>}
            </EnterButton>
        </MenuBottom>
    )
}

const MenuBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 350px;
    margin: 50px;
    background-color: skyblue;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    background: linear-gradient(to top, rgba(169, 204, 214, 0.5), rgba(0, 0, 0, 0)), 
        ${({ imageUrl }) => `url(${imageUrl})`};
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.9;
    cursor: pointer;

    &:hover {
        background: linear-gradient(to top, rgba(188, 226, 236, 0.7), rgba(224, 224, 224, 0.2)), 
            ${({ imageUrl }) => `url(${imageUrl})`};
        background-size: cover;
        background-repeat: no-repeat;
    }
`

const EnterLabel = styled.div`
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &>span {
        font-size: 1.25rem;
    }
`;

const EnterButton = styled.button`
    width: 150px;
    padding-block: 10px;
    border: none;
    border-radius: .9rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.background_color_Black};
    color: white;
    font-size: 1.25rem;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;

    &>span {
        color: ${({theme}) => theme.font_color_Yellow};
        font-size: 1rem;
    }
`;