import { useState, useEffect, useCallback, useContext } from 'react';

import {BOARD_ID} from 'biohealth/context/bioBoardContext'
import { GetBoardPostList } from "api/ArchiveApi";
import PagingComponent from 'biohealth/components/molecule/PagingComponent'
import {ARCHIVE_POST_TYPE} from 'biohealth/components/common/BioEnum'
import {IsWithinDays} from 'biohealth/components/common/Utility'
import BoardCommon from 'biohealth/components/molecule/BoardCommon'

import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

const POST_PER_PAGE = 17;

export default function LinkBoardNew(){
    const location = useLocation();
    const navigate = useNavigate();
    const boardId = useContext(BOARD_ID);
    const userSystemId = localStorage.getItem('systemId');

    const Search = location.search;
    const searchParams = new URLSearchParams(Search);
    const currentPage = Math.max(Number(searchParams.get('page') ?? 1) - 1, 0);

    const [boardList ,setBoardList] = useState([]);
    const [ContentsInfo, setContentsInfo] = useState({});

    const LoadBoardList = useCallback(async(page) => {
        if(!userSystemId || !boardId) return;
        const paramData = {
            ARCHIVE_SYSTEMID: boardId,
            MEMBER_SYSTEMID: userSystemId,
            PAGE_NUMBER: page,
            PAGE_SIZE: POST_PER_PAGE
        };

        const response= await GetBoardPostList({...paramData})
        const {result, msg, data} = response.data;
        if (result === 'Success') {
            if (data) {
                const { MAX_NUM, MAX_PAGE, POST_LIST } = data;
                setContentsInfo({ MAX_NUM, MAX_PAGE });
                setBoardList(POST_LIST);
            } else {
                setContentsInfo({});
                setBoardList();
            }
        } else {
            window.AddAlertMsg({ type: 'error', msg })
        }
    }, [boardId, userSystemId]);

    useEffect(()=>{
        LoadBoardList(Number(currentPage));
    }, [currentPage, LoadBoardList]);

    return (
        <BoardCommon>
            <UlStyle className='noticeBody'>
                {boardList?.map((info) =>{
                    const {POST_IDX, ORDER_IDX, TITLE, CREATED_STAMP, POST_TYPE} = info;
                    const createdStamp = CREATED_STAMP.replace(/-/g, '.').replace(/T/g, '  ');

                    return(
                        <NoticeContent
                            key={POST_IDX}
                            className={(POST_TYPE !== ARCHIVE_POST_TYPE.NORMAL) && 'sticky'}
                            onClick={(e)=>navigate( `/biohealth/board/${POST_IDX}`)}
                        >
                            <p>
                                <span className='number'>{ORDER_IDX}</span>
                                {POST_TYPE === ARCHIVE_POST_TYPE.ANNOUNCEMENT && <BoardTag className="announcement">공지</BoardTag>}
                                {TITLE}
                                {IsWithinDays(createdStamp, 3) && <BoardTag className="new">New</BoardTag>}
                            </p>
                            <p className='timeStamp'>
                                {createdStamp}
                            </p>
                        </NoticeContent>
                    )
                })}
            </UlStyle>

            <NoticeBottom>
                <PagingComponent currentPage={currentPage} contentsInfo={ContentsInfo} />
            </NoticeBottom>
        </BoardCommon>
    )
}

const UlStyle = styled.ul`
    display: flex;
    flex-direction: column;
`;

const NoticeContent = styled.li`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({theme}) => theme.border_color_Gray };
    max-height: 50px;
    flex-grow: 1;
    font-size: 1.4rem;
    color: ${({theme}) => theme.font_color_Black };
    cursor: pointer;
    min-height: 50px
    
    &.sticky{
        background-color: ${({theme}) => theme.background_color_PaleGray};
    }
    
    &:hover{
        background-color: ${({theme}) => theme.background_color_LightBlue};
    }

    p:first-child{
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: 8px;

        span.number{
            text-align: center;
            width: 60px;
            font-size: 1.2rem;
        }
    }

    p.timeStamp{
        width: 170px;
        font-size: 1.1rem;
        opacity: .6;
    }
`;

const NoticeBottom = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
`;

const BoardTag = styled.span`
    color: #FFF;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;

    &.new{
        background-color: #f7823f
    }

    &.announcement{
        border: 1px solid ${({theme}) => theme.font_color_DarkBlue};
        color: ${({theme}) => theme.font_color_DarkBlue};
        font-size: 1rem;
        padding-inline: 12px;
    }
`;