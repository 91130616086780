import { useState, useEffect } from 'react';

import styled from 'styled-components';
import { MdOutlineFirstPage, MdOutlineLastPage, MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const PAGE_OFFSET = 1;
const SHOW_PAGING_NUMBER = 5;
const BASE_URL = '/biohealth/board';

export default function PagingComponent({currentPage, contentsInfo}){
    const navigate = useNavigate()
    const [PageNumber, setPageNumber] = useState([0]);

    useEffect(()=>{
        const pagesArray = Array.from({ length: contentsInfo.MAX_PAGE }, (_, index) => index);
        setPageNumber(pagesArray);
    }, [contentsInfo]);

    const Pagination = () => {
        const maxPageNumber = PageNumber[PageNumber.length - 1];
        const flagNumber = Math.floor(SHOW_PAGING_NUMBER / 2);
        let startNumber = Math.max(currentPage - flagNumber, 0);
        let lastNumber = Math.min(currentPage + flagNumber, maxPageNumber);

        if(startNumber === 0){
            lastNumber = SHOW_PAGING_NUMBER - PAGE_OFFSET;
        }else if(lastNumber === maxPageNumber){
            startNumber = maxPageNumber - (SHOW_PAGING_NUMBER - PAGE_OFFSET);
        }

        return (
            <ul className="pagination">
                {PageNumber.map((pageNum) => {
                    if(pageNum >= startNumber && pageNum <= lastNumber){
                        return (
                            <li
                                key={pageNum}
                                className={pageNum === currentPage ? 'selectPage' : ''}
                                onClick={() =>{
                                    navigate( `${BASE_URL}?page=${pageNum+1}`);
                                }}
                            >
                                {pageNum + PAGE_OFFSET}
                            </li>
                        )
                    }
                    return null;
                })}
            </ul>
        );
    };


    const MovePage = (_type) =>{
        switch (_type) {
            case 'FirstPage':
                    navigate( `${BASE_URL}?page=1`);
                break;
            case 'PrevPage':
                if(currentPage >= 1){
                    navigate( `${BASE_URL}?page=${currentPage}`);
                }
                break;
            case 'NextPage':
                if(currentPage < contentsInfo.MAX_PAGE-1){
                    navigate( `${BASE_URL}?page=${currentPage+2}`);
                }
                break;
            case 'LastPage':
                    navigate( `${BASE_URL}?page=${contentsInfo.MAX_PAGE}`);
                break;
            default:
                break;
        }
    }

    return(
        <PagingContent>
            <span onClick={(e) => MovePage('FirstPage')}><MdOutlineFirstPage /></span>
            <span onClick={(e) => MovePage('PrevPage')}><MdOutlineChevronLeft /></span>
            <Pagination />
            <span onClick={(e) => MovePage('NextPage')}><MdOutlineChevronRight /></span>
            <span onClick={(e) => MovePage('LastPage')}><MdOutlineLastPage /></span>
        </PagingContent>
    )
}

const PagingContent = styled.div`
    display: flex;
    align-items: center;
    height: 48px;

    span:last-child {
        border-right: 1px solid ${({theme}) => theme.border_color_Gray};
    }

    span, ul li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        font-size: 1.4rem;
        box-sizing: border-box;
        cursor:pointer;
        border: 1px solid ${({theme}) => theme.border_color_Gray};
        border-right: none 0;
    }

    span{
        color: ${({theme}) => theme.font_color_Black};

        &:hover{
            background-color: ${({theme}) => theme.background_color_LightBlue};
        }
    }

    ul{
        display: flex;
        align-items: center;
        height: 100%;

        li{
            color: ${({theme}) => theme.font_color_Black};

            &.selectPage, &.selectPage:hover{
                color: #FFF;
                background-color: ${({theme}) => theme.background_color_Blue};
            }

            &:hover{
                background-color: ${({theme}) => theme.background_color_LightBlue};
            }
        }
    }
`;