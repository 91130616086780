import styled from 'styled-components';

export default function BoardCommon({children}){
    return(
        <NoticeContainer>
            <NoticeHeader>
                <h3> 공지사항 </h3>
            </NoticeHeader>
            {children}
        </NoticeContainer>
    )
}

const NoticeContainer = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: calc(100vh - 160px);
    min-height: 900px;
    margin-top: 40px;
    background-color: #fff;
    border-radius: 1.5rem;
    box-shadow: ${({ theme }) => theme.box_shadow_style};
    padding: 32px 40px;
    box-sizing: border-box;

    @media (min-width: 1200px) {
        width: 1200px;
        margin-inline: auto;
    }

    .noticeBody{
        position: relative;
        width: 100%;
        flex-grow: 1;
        border-block: 4px solid ${({theme}) => theme.border_color_Blue };
    }
`;

const NoticeHeader = styled.div`
    position: relative;
    color: ${({theme}) => theme.font_color_Black};
    width: 100%;
    height: 30px;
    margin-bottom: 8px;

    &>h3{
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }
`;