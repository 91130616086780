import { useState, useEffect } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export default function useEnterWorld() {
    const [connection, setConnection] = useState();
    const [messages, setMessages] = useState([]);
    const [connectUser, setConnectUser] = useState({});//연결된 유저 정보
    const [userAudioElementList, setUserAudioElementList] = useState({});//연결된 유저 Audio Element
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    useEffect(() => {
        return async () => {
            if (connection === undefined) return;
            try {
                await connection.stop();
            } catch (e) {
                console.log(e);
            }
        }
    }, [connection]);

    const connectionStart = async (user) => {
        try {
            const connection = new HubConnectionBuilder()
                .withUrl(`/hub/PublishHub`)
                .configureLogging(LogLevel.Information)
                .build();

            connection.on("Res_EnterWorld", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_EnterWorld": message }]));
            connection.on("Res_LeaveWorld", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_LeaveWorld": message }]));
            connection.on("Res_AvatarMove", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_AvatarMove": message }]));
            connection.on("Res_Navigation", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_Navigation": message }]));
            connection.on("From_To_Navigation", (message) => setMessages((prevMessages) => [...prevMessages, { "From_To_Navigation": message }]));
            connection.on("Res_CallRemoteFunction", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_CallRemoteFunction": message }]));
            connection.on("Res_WorldChat", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_WorldChat": message }]));
            connection.on("Res_LocalChat", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_LocalChat": message }]));
            connection.on("Res_WhisperChat", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_WhisperChat": message }]));
            connection.on("Res_VideoStream", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_VideoStream": message }]));
            connection.on("Res_AudioStream", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_AudioStream": message }]));
            connection.on("Res_UpdateVideoOn", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_UpdateVideoOn": message }]));
            connection.on("Res_GetPictureMember", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_GetPictureMember": message }]));
            
            connection.on("Res_SharePDF", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_SharePDF": message }]));
            connection.on("Res_CloseSharedPDF", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_CloseSharedPDF": message }]));
            connection.on("Res_InviteSharedPDF", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_InviteSharedPDF": message }]));
            connection.on("Res_LeaveSharedPDF", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_LeaveSharedPDF": message }]));
            connection.on("Res_UpdatePDFFocus", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_UpdatePDFFocus": message }]));
            connection.on("Res_UpdateHostPageIndex", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_UpdateHostPageIndex": message }]));
            
            connection.onclose(e => {
                setConnection();
                setMessages([]);
            });

            await connection.start();
            setConnection(connection);
            return connection;
        } catch (err) {
            throw err;
        }
    }

    /**
     * 요청 메시지 전송
     * @param {string} msgKey   - 이벤트 명 ex) "EnterWorld", "Navigation", "AvatarMove" 등
     * @param {Object} message  - 메시지 객체 ex) { "PUBLISH_SYSTEMID": "test", "MEMBER_SYSTEMID": "테스트" }
     * @param {Object} _connection - _connection 객체 (기본값: connection)
     */
    const RequestMsg = async (msgKey, message, _connection = connection) => {
        try {
            if (message === undefined)
                await _connection.invoke(msgKey);
            else
                await _connection.invoke(msgKey, message);
        } catch (err) {
            throw err;
        }
    }

    const closeConnection = async () => {
        try {
            await connection.stop();
        } catch (err) {
            throw err;
        }
    }

    window.Re_GetConnectedUsers = (msg) => {
        const jsonMsg = JSON.parse(msg);

        let arrUser = {};
        for (const data of jsonMsg.info) {
            arrUser[data.NICKNAME] = data.PROFILE;
        }

        jsonMsg["profileList"] = arrUser;
        setConnectUser(jsonMsg);
    }

    window.Re_Meta_Leave_World = () => {
        closeConnection();
    }

    window.Re_Meta_Navigation = (FROM_PICTURE_ID, TO_PICTURE_ID, POS_X, POS_Y) => {
        RequestMsg("Navigation", { FROM_PICTURE_ID, TO_PICTURE_ID, POS_X, POS_Y })
    }

    window.Re_Meta_AvatarMove = (FROM_POS_X, FROM_POS_Y, TO_POS_X, TO_POS_Y) => {
        RequestMsg("AvatarMove", { FROM_POS_X, FROM_POS_Y, TO_POS_X, TO_POS_Y, })
    }

    window.Re_Meta_CallRemoteFunction = (EVENT_PICTURE_ID, EVENT_OBJECT_ID, EVENT_INFO) => {
        RequestMsg("CallRemoteFunction", { EVENT_PICTURE_ID, EVENT_OBJECT_ID, EVENT_INFO })
    }

    window.Re_Meta_LocalChat = (MESSAGE, FILE) => {
        RequestMsg("LocalChat", { MESSAGE, FILE })
    }

    window.Re_Meta_WorldChat = (MESSAGE, FILE) => {
        RequestMsg("WorldChat", { MESSAGE, FILE })
    }

    window.Re_Meta_WhisperChat = (TO_MEMBER_SYSTEMID, MESSAGE, FILE) => {
        RequestMsg("WhisperChat", { TO_MEMBER_SYSTEMID, MESSAGE, FILE })
    }

    window.Re_Meta_UpdateCam = (VIDEO_STREAM) => {
        RequestMsg("SendVideoStream", { VIDEO_STREAM })
    }

    window.Re_Meta_UpdateVideoOn = (_VIDEO_ON) => {
        RequestMsg("UpdateVideoOn", { VIDEO_ON: _VIDEO_ON === 1 ? true : false })
    }

    window.Re_Meta_UpdateAudio = (AUDIO_STREAM) => {
        RequestMsg("SendAudioStream", { AUDIO_STREAM })
    }

    window.Re_Meta_GetPictureMember = () => {
        RequestMsg("GetPictureMember")
    }

    const SettingAudio = (UserSystemId) => {
        if (UserSystemId === undefined) return;
        if (userAudioElementList.hasOwnProperty(UserSystemId)) return;

        const audioElement = new Audio();
        const sourceNode = audioContext.createMediaElementSource(audioElement);

        const lowpassFilterNode = audioContext.createBiquadFilter();
        lowpassFilterNode.type = 'lowpass';
        lowpassFilterNode.frequency.setValueAtTime(1000, audioContext.currentTime);

        const highpassFilterNode = audioContext.createBiquadFilter();
        highpassFilterNode.type = 'highpass';
        highpassFilterNode.frequency.setValueAtTime(10000, audioContext.currentTime);

        //볼륨을 설정합니다.
        const gainNode = audioContext.createGain();
        gainNode.gain.value = 2;
        sourceNode.connect(gainNode);
        gainNode.connect(audioContext.destination);

        sourceNode.connect(lowpassFilterNode);
        lowpassFilterNode.connect(highpassFilterNode);
        highpassFilterNode.connect(audioContext.destination);

        setUserAudioElementList(Prev => ({ ...Prev, [UserSystemId]: audioElement }));
    }

    const DeletedAudio = (UserSystemId) => {
        if (UserSystemId === undefined) return;
        if (!userAudioElementList.hasOwnProperty(UserSystemId)) return;

        const updatedList = { ...userAudioElementList };
        delete updatedList[UserSystemId];
        setUserAudioElementList(updatedList);
    }

    return { connection, messages, setMessages, connectionStart, closeConnection, RequestMsg, connectUser, SettingAudio, DeletedAudio, userAudioElementList }
}

/**
 * 사용가능 미디어 권한 체크
 */
export const CheckUseVideo = async (e) => {
    try {
        // 미디어 장치 목록 가져오기
        // const devices = await navigator.mediaDevices.enumerateDevices();

        // 웹캠 권한 체크를 위해 빈 객체를 사용 (비디오 장치만 필요한 경우)
        const constraints = { video: {} };

        // 웹캠에 대한 권한 요청
        const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        const isUseVideo = mediaStream !== null ? true : false;

        return isUseVideo;
    } catch (err) {
        throw err;
    }
}

/**
 * 사용가능 미디어 권한 체크
 */
export const CheckUseAudio = async (e) => {
    try {
        const constraints = { audio: {} };

        // 웹캠에 대한 권한 요청
        const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        const isUseAudio = mediaStream !== null ? true : false;

        return isUseAudio;
    } catch (err) {
        throw err;
    }
}