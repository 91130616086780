import styled from "styled-components";

export const Layout = styled.div`
    @media screen and (min-width:1024px) {
        max-width: 1200px;
    }
    @media screen and (min-width:768px) and (max-width:1023px) {
        padding-left: 16px;
        padding-right: 16px;
    }
    @media screen and (max-width:767px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`

export const KhnpGg = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    background-image: url('/digitalTwin/img/khnp_bg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    z-index:-10;
`;