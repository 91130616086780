import styled from "styled-components";

function BioHealthFooter(){
    return(
        <FooterContent>
            <div style={{ display:"flex", alignItems:"center" }}>
                <div className="footer_logo">
                    <LogoImage src='bioHealth/img/logo.avif' alt='logo' className='Nav_logo'/>
                </div>
                <ParagraphBox>
                    <div>개인정보처리방침</div>
                    <h3>바이오헬스 혁신융합대학 사업단</h3>
                    <h3>COPYRIGHT© BIOCOSS UNIVERSITY. ALL RIGHTS RESERVED</h3>
                </ParagraphBox>
            </div>
        </FooterContent>
    )
}
export default BioHealthFooter;

//스타일------------------------
const FooterContent = styled.footer`
    width: 100%;
    padding-top: 75px;
    padding-bottom: 75px;
    display: flex;
    justify-content: center; /* 수평 중앙 정렬 */
    align-items: center; /* 수직 중앙 정렬 */
    /* border: 1px solid black; */
    flex-direction: column; /* 요소들이 수직으로 배치되도록 */
`;

const ParagraphBox = styled.div`
    margin-left: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    line-height: normal;
    
    &> div {
        cursor: pointer;
    }
`

const LogoImage = styled.img`
    width: 300px;
    height: 50px;
`;