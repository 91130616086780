import {useState, useEffect, useCallback}from 'react';

//모듈 로드
export default function useEnuSpaceModule(){
    const [enuSpace, setEnuSpace] = useState('');

    const getEnuSpaceModule = useCallback(async () => {
        const result = await wasmCheck();

        if(result){
            setEnuSpace(window.g_enuSpace);
            window.g_enuSpace.StartLoop();
        }
    }, []);

    useEffect(()=>{
        getEnuSpaceModule();
    }, [getEnuSpaceModule]);

    const StartLoop = () =>{
        if(enuSpace === '')
            return;
            
        enuSpace.StartLoop();
    }

    return [enuSpace, StartLoop]
}

function wasmCheck() {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            console.log("Checking window.g_wasm...");
            if (window.g_wasm) {
                clearInterval(interval); // 조건이 충족되면 interval을 정리하여 반복을 멈춤
                console.log("g_wasm is true, resolving...");
                resolve(true); // 조건이 충족되었을 때 true를 반환
            }
        }, 1000); // 1초마다 확인
    });
}