
import {useEffect, useState, useCallback, useContext} from "react";

import {ARCHIVE_POST_TYPE} from 'biohealth/components/common/BioEnum'
import {BOARD_ID} from 'biohealth/context/bioBoardContext'
import {IsWithinDays} from 'biohealth/components/common/Utility'

import styled from "styled-components";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { GetBoardPostList } from "api/ArchiveApi";

const POST_PER_PAGE = 7;

const IntroNotice = () => {
    const navigate = useNavigate();
    const boardId = useContext(BOARD_ID);
    const userSystemId = localStorage.getItem('systemId');
    const [boardList ,setBoardList] = useState([]);

    const LoadBoardList = useCallback(async(userSystemId) => {
        if(!userSystemId || !boardId) return;
        const paramData = {
            ARCHIVE_SYSTEMID: boardId,
            MEMBER_SYSTEMID: userSystemId,
            PAGE_NUMBER: 0,
            PAGE_SIZE: POST_PER_PAGE
        };

        const response= await GetBoardPostList({...paramData})
        const {result, msg, data} = response.data;
        if (result === 'Success') {
            if (data) {
                const { POST_LIST } = data;
                setBoardList(POST_LIST);
            } else {
                setBoardList();
            }
        } else {
            window.AddAlertMsg({ type: 'error', msg })
        }
    }, [boardId]);

    useEffect(()=>{
        if(userSystemId)
            LoadBoardList(userSystemId);
    }, [userSystemId, LoadBoardList]);

    const RenderBoardDecoration = (postType, createdStamp) =>{
        const isStickyOrAnnouncement = 
                    postType === ARCHIVE_POST_TYPE.STICKY || 
                    postType === ARCHIVE_POST_TYPE.ANNOUNCEMENT;

    return (
        <>
            {isStickyOrAnnouncement && <BoardTag className="stick">공지사항</BoardTag>}
            {IsWithinDays(createdStamp, 3) && <BoardTag className="new">New</BoardTag>}
        </>
    );
    }

    return (
        <Container>
            <NoticeContainer>
                <NoticeHeader>
                    <h3> 공지사항 </h3>
                    <p onClick={(e)=> navigate('/biohealth/board')}> 더 보기 <span><IoIosArrowDroprightCircle /></span> </p>
                </NoticeHeader>

                <NoticeBox>
                    {boardList?.map((post, index) =>{
                        const { TITLE, CREATED_STAMP, POST_TYPE, POST_IDX} = post;

                        return(
                            <NoticeContent 
                                key={index}
                                onClick={(e)=> navigate( `/biohealth/board/${POST_IDX}`)}
                            >
                                <p>
                                    {TITLE}
                                    {RenderBoardDecoration(POST_TYPE, CREATED_STAMP)}
                                </p>
                                <p className="timeTemp">{CREATED_STAMP?.slice(0, 10)}</p>
                            </NoticeContent>
                        )
                    })}
                </NoticeBox>
            </NoticeContainer>
        </Container>
    )
}

export default IntroNotice;

//스타일----------------------------------
const Container = styled.div`
    display: flex;
    justify-content: center;
    padding-block: 30px;
    width: 100%;
    height: 100%;
    background: white;
`;

const NoticeContainer = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 1200px;
    border-radius: 1.5rem;
    box-shadow: rgba(117, 117, 117, 0.09) 0px 3px 20px;
    padding: 16px 32px;
    box-sizing: border-box;
`;

const NoticeBox = styled.div`
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const NoticeHeader = styled.div`
    position: relative;
    color: ${({theme}) => theme.font_color_Black};
    width: 100%;
    height: 30px;
    margin-bottom: 8px;

    &>h3{
        flex-grow: 1;
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center;
    }

    p{
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 10px;
        cursor: pointer;
        font-size: 1.1rem;
        opacity: .8;

        &>span{
            margin-left: 6px;
        }

        &:hover{
            color: ${({theme}) => theme.icon_blue};
            opacity: 1;

            &>span{
                transform: scale(1.2);
                transition: transform 0.1s ease-in-out;
            }
        }
    }
`;

const NoticeContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* height: 30px; */
    font-size: 1.2rem;
    font-style: normal;
    letter-spacing: 0.5px;
    cursor: pointer;
    border-bottom: 1px solid ${({theme}) => theme.border_color_LightGray};
    padding-block: 14px;

    &>p {
        padding-inline: 14px;

        &:first-child{
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .timeTemp{
        font-size: 1rem;
        opacity: .6;
    }

    &:hover {
        background-color: ${({theme}) => theme.background_color_LightBlue};
    }
`

const BoardTag = styled.span`
    color: #FFF;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;

    &.new{
        background-color: #f7823f
    }

    &.stick{
        background-color: #4AE109
    }
`;