import axios from 'axios';
import React, { useState } from 'react';
import styled from 'styled-components';

// const getFormattedDate = () => {
//     const now = new Date();
//     return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}-${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
// };

const SurveyForm = () => {
    // 상태 관리
    const [patientInfo, setPatientInfo] = useState({
        name: '',
        gender: '',
        birth_date: '',
        contactInfo: '',
        address: '',
    });

    const [symptoms, setSymptoms] = useState({
        symptom_description: '',
        symptom_start_date: '',
        severity: '',
        frequency: '',
    })

    const [medicalHistory, setMedicalHistory] = useState({
        disease_name: '',
        medication: '',
        allergies: '',
        family_history: ''
    });

    const [lifestyle, setLifestyle] = useState({
        smoking: false,
        smoking_amount: '',
        alcohol: false,
        alcohol_frequency: '',
        exercise_frequency: '',
        diet: ''
    });

    const [notes, setNotes] = useState('');

    // 폼 입력 핸들러
    const handlePatientInfoChange = (e) => {
        const { name, value } = e.target;
        setPatientInfo({
            ...patientInfo,
            [name]: value
        });
    };

    const handleSymtomsChange = (e) => {
        const { name, value } = e.target;
        setSymptoms({
            ...symptoms,
            [name]: value
        })
    }

    const handleMedicalHistoryChange = (e) => {
        const { name, value } = e.target;
        setMedicalHistory({
            ...medicalHistory,
            [name]: value
        });
    };

    const handleLifestyleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setLifestyle({
            ...lifestyle,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    // const handleAdditionalInfoChange = (e) => {
    //     const { name, value } = e.target;
    //     setAdditionalInfo({
    //         ...additionalInfo,
    //         [name]: value
    //     })
    // };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    };

    // 폼 제출 핸들러
    const handleSubmit = async (e) => {
        e.preventDefault();

        const getFormattedDate = () => {
            const now = new Date();
            return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
        };
    
        try {
            // surveyData 객체에서 patient_id를 제거
            const surveyData = {
                // 환자 정보
                // patient_id는 생략하여 서버가 자동으로 생성
                name: patientInfo.name,
                gender: patientInfo.gender,
                birth_date: patientInfo.birth_date,
                contact_info: patientInfo.contactInfo,
                address: patientInfo.address,
    
                // 증상 정보
                symptom_description: symptoms.symptom_description,
                symptom_start_date: symptoms.symptom_start_date || null,
                severity: symptoms.severity || null,
                frequency: symptoms.frequency || null,
    
                // 기존 의료 기록
                disease_name: medicalHistory.disease_name || null,
                medication: medicalHistory.medication || null,
                allergies: medicalHistory.allergies || null,
                family_history: medicalHistory.family_history || null,
    
                // 생활 습관
                smoking: lifestyle.smoking,
                smoking_amount: lifestyle.smoking_amount || null,
                alcohol: lifestyle.alcohol,
                alcohol_frequency: lifestyle.alcohol_frequency || null,
                exercise_frequency: lifestyle.exercise_frequency || null,
                diet: lifestyle.diet || null,
    
                // 기타
                notes: notes || null,
                submission_date: getFormattedDate(),
            };
    
            const convertData = new URLSearchParams(surveyData);
    
            // 설문지 입력폼 제출 > post 요청
            const response = await axios.post('http://127.0.0.1:8080/api/patients/registerSurvey', convertData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
    
            if (response.status === 200) {
                alert("설문이 성공적으로 제출되었습니다.");
            } else {
                alert("설문지 등록 실패");
            }
        } catch (error) {
            console.log("Error submitting the survey: ", error);
            alert("Fetch Error");
        }
    };

    return (
        <div style={{ 
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            width:"100%", 
            height:"100vh",  
            backgroundImage: "linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(bioHealth/img/background.jpg)",
            backgroundRepeat:"no-repeat",
            backgroundSize:"cover"
        }}>
            <FormContainer>
                <form onSubmit={handleSubmit}>
                    <TitieBox>진단용 설문 작성</TitieBox>
                    <FormField>
                        <Label>이름 *</Label>
                        <Input
                            type="text"
                            name="name"
                            value={patientInfo.name}
                            onChange={handlePatientInfoChange}
                            required />
                    </FormField>
                    <FormField>
                        <Label>성별 *</Label>
                        <Select
                            name="gender"
                            value={patientInfo.gender}
                            onChange={handlePatientInfoChange}
                            required
                        >
                            <option value="">선택</option>
                            <option value="male">남성</option>
                            <option value="female">여성</option>
                        </Select>
                    </FormField>
                    <FormField>
                        <Label>생년월일 *</Label>
                        <Input
                            type="date"
                            name="birth_date"
                            value={patientInfo.birth_date}
                            onChange={handlePatientInfoChange}
                            required 
                        />
                    </FormField>
                    <FormField>
                        <Label>연락처 *</Label>
                        <Input
                            type="tel"
                            name="contactInfo"
                            value={patientInfo.contactInfo}
                            onChange={handlePatientInfoChange}
                            required 
                        />
                    </FormField>
                    <FormField>
                        <Label>주소 *</Label>
                        <Input
                            type="address"
                            name="address"
                            value={patientInfo.address}
                            onChange={handlePatientInfoChange}
                            required 
                        />
                    </FormField>

                    <SectionTitle>증상</SectionTitle>
                    <FormField>
                        <Label>증상설명 *</Label>
                        <Input
                            type="text"
                            name="symptom_description"
                            value={symptoms.symptom_description}
                            onChange={handleSymtomsChange}
                            required 
                        />
                    </FormField>
                    <FormField>
                        <Label>증상시작일</Label>
                        <Input
                            type="date"
                            name="symptom_start_date"
                            value={symptoms.symptom_start_date}
                            onChange={handleSymtomsChange} 
                        />
                    </FormField>
                    <FormField>
                        <Label>증상 심각도(1---10)</Label>
                        <Input
                            type="text"
                            name="severity"
                            value={symptoms.severity}
                            onChange={handleSymtomsChange}
                        />
                    </FormField>
                    <FormField>
                        <Label>증상 빈도</Label>
                        <Input
                            type="text"
                            name="frequency"
                            value={symptoms.frequency}
                            onChange={handleSymtomsChange}
                        />
                    </FormField>

                    <SectionTitle>기존 질환 여부</SectionTitle>
                    <FormField>
                        <Label>질환명</Label>
                        <Input
                            type="text"
                            name="disease_name"
                            value={medicalHistory.disease_name}
                            onChange={handleMedicalHistoryChange}
                        />
                    </FormField>
                    <FormField>
                        <Label>복용 중인 약물</Label>
                        <Textarea
                            name="medication"
                            value={medicalHistory.medication}
                            onChange={handleMedicalHistoryChange} 
                        />
                    </FormField>
                    <FormField>
                        <Label>알레르기 정보</Label>
                        <Textarea
                            name="allergies"
                            value={medicalHistory.allergies}
                            onChange={handleMedicalHistoryChange} 
                        />
                    </FormField>
                    <FormField>
                        <Label>가족 병력</Label>
                        <Textarea
                            name="family_history"
                            value={medicalHistory.family_history}
                            onChange={handleMedicalHistoryChange} 
                        />
                    </FormField>

                    <SectionTitle>생활 습관</SectionTitle>
                    <FormField>
                        <Label>흡연 여부 *</Label>
                        <Input
                            type="checkbox"
                            name="smoking"
                            checked={lifestyle.smoking}
                            onChange={handleLifestyleChange} 
                            required
                        />
                        <Label>흡연 양 (1일 횟수)</Label>
                        <Input
                            type="number"
                            name="smoking_amount"
                            value={lifestyle.smoking_amount}
                            onChange={handleLifestyleChange} 
                        />
                    </FormField>
                    <FormField>
                        <Label>음주 여부 *</Label>
                        <Input
                            type="checkbox"
                            name="alcohol"
                            checked={lifestyle.alcohol}
                            onChange={handleLifestyleChange} 
                            required
                        />
                    </FormField>
                    <FormField>
                        <Label>음주 빈도</Label>
                        <Select
                            name="alcohol_frequency"
                            value={lifestyle.alcohol_frequency}
                            onChange={handleLifestyleChange}>
                            <option value="">선택</option>
                            <option value="daily">매일</option>
                            <option value="weekly">주 1회</option>
                            <option value="monthly">주 2~3회</option>
                        </Select>
                    </FormField>
                    <FormField>
                        <Label>운동 빈도</Label>
                        <Select
                            name="exercise_frequency"
                            value={lifestyle.exercise_frequency}
                            onChange={handleLifestyleChange}>
                            <option value="">선택</option>
                            <option value="daily">매일</option>
                            <option value="weekly">주간</option>
                            <option value="monthly">월간</option>
                            <option value="never">안 함</option>
                        </Select>
                    </FormField>
                    <FormField>
                        <Label>식습관(자유롭게 적어주세요.)</Label>
                        <Textarea
                            name="diet"
                            value={lifestyle.diet}
                            onChange={handleLifestyleChange} 
                        />
                    </FormField>

                    <SectionTitle>기타 사항</SectionTitle>
                    <FormField>
                        <Label>추가 기재 사항</Label>
                        <Textarea
                            name="notes"
                            value={notes}
                            onChange={handleNotesChange} 
                        />
                    </FormField>

                    <div style={{ display:"flex", justifyContent:"flex-end" }}>
                        <Button type="submit">제출하기</Button>
                    </div>
                </form>
            </FormContainer>
        </div>
    );
};

export default SurveyForm;


// 스타일-----------------------------------------------------------
const FormContainer = styled.div`
    padding: 50px;
    width: 700px;
    margin: 0 auto;
    background-color: #fafafa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 1080px; /* 최대 높이 설정 */
    overflow-y: auto;  /* 높이를 넘으면 스크롤 활성화 */
`;

const SectionTitle = styled.h2`
    margin-bottom: 20px;
    font-size: 1.5rem;
`;

const TitieBox = styled.h1`
    margin-bottom: 20px;
    font-size: 1.7rem;
`

const FormField = styled.div`
    margin-bottom: 15px;
    padding-right: 20px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const Select = styled.select`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const Button = styled.button`
    padding: 10px 15px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
        background-color: #45a049;
    }
`;
