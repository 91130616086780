const bioTheme = {
    border_color_Blue: '#2196F3',
    border_color_Gray: '#b3b3b3',
    border_color_LightGray: '#E6E6E6',

    font_color_Black: '#2B2B2B',
    font_color_Gray: '#B3B3B3',
    font_color_Yellow: '#FFD30F',
    font_color_DarkBlue: '#1565C0',

    background_color_Blue: '#2196F3',
    background_color_LightBlue: '#E3F2FD',
    background_color_Black : '#222',
    background_color_PaleGray: '#F2F2F2',
    background_color_LightGray: '#E6E6E6',
    background_color_Skyblue: 'skyblue',
    
    icon_blue: '#2eb5eb',

    box_shadow_style: 'rgba(117, 117, 117, 0.09) 0px 3px 20px',
};

export default bioTheme;