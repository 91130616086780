import { useState, useEffect } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export default function useEnterEnuCanvas() {
  const [connection, setConnection] = useState();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    connectionStart();
  }, []);

  useEffect(() => {
    return async () => {
      if (connection === undefined) return;
      try {
        await connection.stop();
      } catch (e) {
        console.log(e);
      }
    }
  }, [connection]);

  const connectionStart = async () => {
    try {
      const connection = new HubConnectionBuilder()
        .withUrl(`/hub/EnuCanvasHub`)
        .configureLogging(LogLevel.Information)
        .build();

        connection.on("Res_EnterEnuCanvas", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_EnterEnuCanvas": message }]));
        connection.on("Res_Project_Info", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_Project_Info": message }]));
        connection.on("Res_LeaveWorld", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_LeaveWorld": message }]));
        connection.on("Res_CreateNode", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_CreateNode": message }]));
        connection.on("Res_DeleteNode", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_DeleteNode": message }]));
        connection.on("Res_SelectNode", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_SelectNode": message }]));
        connection.on("Res_DeselectNode", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_DeselectNode": message }]));
        connection.on("Res_UpdateNode", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_UpdateNode": message }]));
        connection.on("Res_CursorPoint", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_CursorPoint": message }]));
        connection.on("Res_CreateSVG", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_CreateSVG": message }]));
        connection.on("Res_DeleteSVG", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_DeleteSVG": message }]));
        connection.on("Res_MoveSVG", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_MoveSVG": message }]));
        connection.on("Res_CreateTemp", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_CreateTemp": message }]));
        connection.on("Res_UpdateTemp", (message) => setMessages((prevMessages) => [...prevMessages, { "Res_UpdateTemp": {MESSAGE:'Success',...message} }]));

      connection.onclose(e => {
        setConnection();
        setMessages([]);
        setUsers([]);
      });

      await connection.start();
      setConnection(connection);
      return connection;
    } catch (err) {
      throw err;
    }
  }

  /**
   * 요청 메시지 전송
   * @param {string} msgKey   - 이벤트 명 ex) "EnterWorld", "Navigation", "AvatarMove" 등
   * @param {Object} message  - 메시지 객체 ex) { "PUBLISH_SYSTEMID": "test", "MEMBER_SYSTEMID": "테스트" }
   * @param {Object} _connection - _connection 객체 (기본값: connection)
   */
  const RequestMsg = async (msgKey, message, _connection = connection) => {
    try {
      await _connection.invoke(msgKey, message);
    } catch (err) {
      throw err;
    }
  }

  const closeConnection = async () => {
    try {
      await connection.stop();
    } catch (err) {
      throw err;
    }
  }

  // const EnterCanvasWorld = (PUBLISH_SYSTEMID, MEMBER_SYSTEMID, PROJECTNAME, NICKNAME) =>{
  //   RequestMsg("EnterEnuCanvas", {PUBLISH_SYSTEMID, MEMBER_SYSTEMID, PROJECTNAME, NICKNAME}, connection);
  //   RequestMsg("CreateNode", {PARENT_ID, TYPE, TAG}, connection);
  // }

  return { connection, messages, setMessages, users, connectionStart, closeConnection, RequestMsg}
}