import BioHealthMain from 'biohealth/components/BioHealthMain';
import SurveyForm from 'biohealth/components/patient/SurveyForm';
import SelectTarget from 'biohealth/components/SelectTarget';
import BioBoard from 'biohealth/components/page/BioBoard'
import BioBoardDetail from 'biohealth/components/page/BioBoardDetail'

const BioRoutes = [
    // 바이오헬스 플랫폼 인트로
    {
        path: '/biohealth/intro',
        element: <BioHealthMain />,
        isCheckToken: true
    },
    // 환자 > 진료 설문
    {
        path: '/biohealth/patientSurvey',
        element: <SurveyForm />,
        isCheckToken: true,
    },
    // 의사/환자 선택
    {
        path: '/biohealth/selectTarget',
        element: <SelectTarget />,
        isCheckToken: true,
    },
    {
        path: 'biohealth/board',
        element: <BioBoard />,
        isCheckToken : true
    },
    {
        path: '/biohealth/board/:postIdx',
        element: <BioBoardDetail />,
        isCheckToken: true
    },
];

export default BioRoutes;
