import DBTagPageHeader from "components/organism/DBTagPageHeader";
import DBTagPageSchedule from "components/organism/DBTagPageSchedule";
import DBTagPageTagList from "components/organism/DBTagPageTagList";
import ModalSpace from "components/modal_new/ModalSpace";
import useEnuSpaceModule from 'hooks/useEnuSpaceModule';

import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

export default function DBTag(){
    const location = useLocation();
    const {Device, UserId, Email, Lever} = location.state;
    const [enuSpace] = useEnuSpaceModule();
    
    return (
        <DevicePageContent>
            <ModalSpace enuSpace={enuSpace}/>
            <DBTagPageHeader userId={UserId} email={Email} lever={Lever} deviceInfo={Device} />
            <BorderLine />
            <DBTagPageSchedule height={`240px`} userId={UserId} lever={Lever} deviceInfo={Device} />
            <BorderLine />
            <DBTagPageTagList height={`calc(100% - 344px)`} userId={UserId} lever={Lever} deviceInfo={Device} />
        </DevicePageContent>
    )
}

const DevicePageContent = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 72px);
    user-select: none;
    z-index: 13;

    @media screen and (min-width:1024px) {
        max-width: 1200px;
        margin: 0 auto;
    }
`;

const BorderLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({theme}) => theme.base.border_color_Gray};
    /* margin: 16px 0; */
    margin-top: 8px;
    margin-bottom: 16px;
`;