import BioHealthHeader from 'biohealth/components/BioHealthHeader';
import BioHealthFooter from 'biohealth/components/BioHealthFooter';
import bioTheme from 'biohealth/css/bioTheme'

import {BOARD_ID} from 'biohealth/context/bioBoardContext'

import Notification from "components/atom/Notification";
import { ThemeProvider } from 'styled-components';
import styled from "styled-components";

export default function BioLayout({children}){
    return(
        <ThemeProvider theme={bioTheme}>
            <Notification />
            "https://192.168.0.240:8080/Board/link?border=ce62eeeda2074ea8b54f593abd9f0434&page=1"
            <BOARD_ID.Provider value={"ce62eeeda2074ea8b54f593abd9f0434"}>
                <BioHealthContent>
                    <BioHealthHeader />
                    {children}
                    <BioHealthFooter />
                </BioHealthContent>
            </BOARD_ID.Provider>
        </ThemeProvider>
    )
}

const BioHealthContent = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 500px;
    min-width: 767px;
    background-image: 
        linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)),
        linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)),
        url('bioHealth/img/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
`;