import { useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';

import RuntimeModeContent from "components/template/RuntimeModeContent";
import useEnuSpaceModule from 'hooks/useEnuSpaceModule';
import { GetPublishModeInfo} from 'api/RepositoryApi'
import useAudio from 'hooks/useAudio';
import {GuestLogin} from 'hooks/useMember'

import { setBaseData, InitProjectData } from 'reducers/projectDataReducer'
import { AllClearModalPopup } from 'reducers/modulePopupReducer';
import { useLocation } from 'react-router-dom'

function LinkView(){
    const [enuSpace] = useEnuSpaceModule();
    const location = useLocation();
    const dispatch = useDispatch();
    const systemId = localStorage.getItem('systemId') || sessionStorage.getItem('systemId');
    const nickname = localStorage.getItem('nickname') || sessionStorage.getItem('nickname');
    useAudio(enuSpace); // 오디오 재생

    const currentURL = location.pathname;
    const [isIframe, setIsIframe] = useState(false);

    useEffect(() => {
        setIsIframe(window.self !== window.top);
    }, []);

    const [BASE_DATA, setBASE_DATA] = useState({
        userId: systemId,
        userNickname: nickname,
        projectId: '',
        projectName: '',
        isMetaverse: false,
        currentURL,
    });
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        const Search = location.search;
        const ArrayProjectInfo = Search.substring(1, Search.length).split('&');
        const projectId = ArrayProjectInfo[0];
        const projectName = ArrayProjectInfo[1];
        const title = ArrayProjectInfo[2];
        dispatch(setBaseData({ projectId, projectName }));

        sessionStorage.setItem('publishId', ArrayProjectInfo[0]);
        localStorage.removeItem('projectId');

        if (title) {
            document.title = decodeURIComponent(title);
        }

        GetPublishModeInfo(ArrayProjectInfo[0])
            .then((res) => {
                const { result, msg, data } = res.data;
                if (result === 'Success') {
                    setBASE_DATA(prevBASE_DATA => ({
                        ...prevBASE_DATA,
                        projectId,
                        projectName,
                        isMetaverse: data.USE_METAVERSE === 1,
                    }));
                    setIsDataLoaded(true);
                } else
                    console.log(msg);
            }).catch((err) => {
                console.log(err);
            });
    }, [dispatch, location]);

    useEffect(() => {
        if (BASE_DATA?.userId === null && BASE_DATA?.isMetaverse === 0) {
            GuestLogin('')
                .then(res => {
                    const { result, msg } = res.data;
                    if (result === 'Success') {
                        const {
                            Authorization,
                            MEMBER_SYSTEMID,
                            NICKNAME,
                            RefreshToken
                        } = JSON.parse(msg);

                        sessionStorage.setItem('systemId', MEMBER_SYSTEMID);
                        sessionStorage.setItem('Authorization', Authorization);
                        sessionStorage.setItem('refreshtoken', RefreshToken);
                        sessionStorage.setItem('nickname', NICKNAME);

                    }
                })
        }

        return () => {
            sessionStorage.removeItem('Authorization');
            sessionStorage.removeItem('refreshtoken');
            sessionStorage.removeItem('systemId');
            sessionStorage.removeItem('nickname');
            sessionStorage.removeItem('publishId');
        }
    }, [BASE_DATA?.userId, BASE_DATA?.isMetaverse]);

    useEffect(() => {
        dispatch(InitProjectData());

        return () => {
            dispatch(AllClearModalPopup());
        };
    }, [dispatch]);

    return(
        isDataLoaded && <RuntimeModeContent enuSpace={enuSpace} _BASE_DATA={BASE_DATA} isIframe={isIframe} viewType='link'/>
    )   
}

export default LinkView;

//스타일-------------------------