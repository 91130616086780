import { useState, useEffect } from 'react';

import ColorChip from 'components/atom/ColorChip'
import { GroupName } from 'components/css/common';
import OptionToggleBtn from "components/atom/OptionToggleBtn";
import useColorInfo from 'hooks/useColorInfo'
import {VisibleIcon, HiddenIcon} from 'components/common/EnuCanvasIcon'
import {hexToRgb} from 'components/Utility';

import styled from 'styled-components';
import { useSelector } from 'react-redux';

export default function ColorComponent({label, labelWidth=56, attributeName, changeEvent, changePickerEvent, disabled, isOpacity=true, isVisibility = true, isGradient = true}){
    const nodeData = useSelector(state => state.selectNodeData);
    const [ColorData, setColorData] = useState({fill: '', opacity: ''});
    const ComponentId = attributeName || label;
    const targetKey = ComponentId.replace(/-/g, '_');
    const {
        [targetKey]: rgb,
        [`${targetKey}_opacity`]: opacity,
        gradient: gradientInfoMap,
    } = nodeData;

    const {ColorValue, ColorDataMap, GradientType, IsGradient, setColorValue} = useColorInfo({rgb, opacity, gradientInfoMap});

    useEffect((e)=>{
        const {fill, opacity} = ColorDataMap;
        setColorData({
            fill, 
            opacity: opacity === "Mixed" ?  "Mixed" : (opacity ?? 1) * 100,
        });
    }, [ColorDataMap]);

    const ChangeColor = (type, value) => {
        if(type === 'fill'){
            const targetValue = value.replace(/[^0-9a-fA-F]/g, '');
            setColorData(prev => ({...prev, [type]:targetValue}))
        }else if(type === 'opacity'){
            const  targetValue = value.replace(/[^0-9]/g, '');
            setColorData(prev => ({...prev, [type]:targetValue}))
        }
    }

    const KeyDownEvent = (e) => {
        const {key, ctrlKey, target} = e;
        
        if(key === 'Enter') 
            target.blur();
        else if (ctrlKey && key === 'a'){
            e.preventDefault();
            target.select();
        }
    };
    
    const BlurEvent = (e) => {
        const {id: attributeName , value} = e.target;
        let attributeValue = value;

        if(value === '' || value === 'Mixed'){
            const {fill, opacity} = ColorDataMap;
            setColorData({
                fill, 
                opacity: opacity=== "Mixed" ?  "Mixed" : (opacity ?? 1) * 100,
            });
            return;
        }

        if(attributeName.includes('opacity')){
            attributeValue = Math.min(Math.max(attributeValue, 0), 100) / 100;
        }else {
            if (attributeValue.length === 3) {
                attributeValue = attributeValue.split('').map(char => char + char).join('');
                const fill = attributeValue;
                setColorData(prev => ({...prev, fill}))
            }
            
            if(attributeValue.length === 6) {
                attributeValue = hexToRgb(attributeValue)
            }else {
                const { fill, opacity } = ColorDataMap;
                setColorData({
                    fill, 
                    opacity: opacity=== "Mixed" ?  "Mixed" : (opacity ?? 1) * 100,
                });
                return;
            }
        }

        changeEvent && changeEvent([{ attributeName, attributeValue}]);
    };
    
    const RenderColorInput = () => {
        const {fill, opacity} = ColorData;

        if (IsGradient) {
            return <span className='gradientName'>{GradientType.replace('-gradient', '')}</span>
        }else if(fill === 'Gradient'){
            return <span className='gradientName'>-</span>
        }else {
            const opacityId = `${ComponentId}-opacity`;

            const commonProps = {
                onKeyDown: KeyDownEvent,
                onBlur: BlurEvent,
                onClick: (e) => { if (e.target.value === 'Mixed') e.target.select(); }
            }

            return (
                <InputSpace labelWidth={labelWidth} isVisibility={isVisibility}>
                    <ColorInputBox
                        id={ComponentId}
                        name={ComponentId}
                        title={ComponentId}
                        type="text"
                        value={fill || ''}
                        maxLength={6}
                        disabled={disabled}
                        onChange={(e) => ChangeColor('fill', e.target.value)}
                        {...commonProps}
                    />
                    {isOpacity &&
                        <OpacityInputBox
                            id={opacityId}
                            name={opacityId}
                            title={opacityId}
                            type={opacity === 'Mixed' ? 'text' : 'number' }
                            value={opacity || '0'}
                            min="0" 
                            max="100"
                            disabled={disabled}
                            onChange={(e) => ChangeColor('opacity', e.target.value)}
                            {...commonProps}
                        />
                    }
                </InputSpace>
            )
        }
    }
    
    return(
        <Content>
            <ColorChip 
                attributeName={ComponentId} 
                rgba={ColorValue} 
                changeEvent={changeEvent} 
                changePickerEvent={changePickerEvent} 
                isOpacity={isOpacity}
                isGradient={isGradient}
                setColorValue={setColorValue}
                disabled={disabled}
            />

            <GroupLabel width={labelWidth} htmlFor={ComponentId}>{label}</GroupLabel>
            {RenderColorInput()}
            
            {isVisibility && 
                (!Array.isArray(nodeData[`${targetKey}_visibility`]) 
                ?
                <OptionToggleBtn
                    title={`${(attributeName || label)}-visibility`}
                    disabled={disabled}
                    onClick={(e) => {
                        changeEvent &&
                            changeEvent([{
                                attributeName: `${(attributeName || label)}-visibility`,
                                attributeValue: nodeData[`${targetKey}_visibility`] === '1' ? '0' : '1',
                            }]);
                    }}
                >
                    {nodeData[`${targetKey}_visibility`] === '1' ? <VisibleIcon /> : <HiddenIcon />}
                </OptionToggleBtn>
                :
                <OptionToggleBtn disabled={true} />)
            }
        </Content>
    )
}

const Content = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    .gradientName{
        font-weight: 500;
        flex-grow: 1;
        min-width: 60px;
    }
`;

const GroupLabel = styled(GroupName)`
    padding: 6px 0 6px 8px;
`;

const InputSpace = styled.div`
    position: relative;
    height: 100%;
    width: ${({labelWidth, isVisibility})=> `calc(100% - 28px - ${isVisibility ? '16px' : '0px'} - ${labelWidth}px )`};
    display: flex;
    gap: 6px;
`;

const InputBase = styled.input`
    font-size: .9rem;
    height: 28px;
    box-sizing: border-box;
    border: 0 none;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};

    &:hover{
        border-color: ${({theme}) => theme.base.border_color_Blue};
    }
    &:focus{
        outline:none;
        border-bottom: 1px solid ${({theme}) => theme.base.border_color_Blue};
    }
    &:selection{
        border: 0 none;
        background-color: ${({theme}) => theme.base.background_color_lightBlue};
        border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    }
    &:disabled{
        border-color: transparent;
    }
`;

const ColorInputBox = styled(InputBase)`
    flex-grow: 1;
    width: calc(100% - 50px);
`;

const OpacityInputBox = styled(InputBase)`
    width: 42px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;