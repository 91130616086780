import React, {useState, useEffect, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import EnuFileModule from "components/atom/EnuFileModule"
import ProjectPageContent from "components/template/ProjectPageContent";
import {LoadingLayer} from "components/organism/LoadingPage";
import useEnuSpaceModule from 'hooks/useEnuSpaceModule';
import useLoadProject from 'hooks/useLoadProject';
import useWindowFun from 'hooks/useWindowFun';
import useProjectData from 'hooks/useProjectData';
import useAudio from 'hooks/useAudio';
// import GetSQLiteFileList from 'hooks/useSQLite';

import { useNavigate } from 'react-router-dom'
import { setBaseData, setTreeData, deleteTreeData, InitProjectData, AllClearCanvasView} from 'reducers/projectDataReducer'
import { AllClearModalPopup } from 'reducers/modulePopupReducer';
import { showRightMenu } from 'reducers/rightMenuReducer';

function EditModePage({isTemplate = false}){
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {ProjectSave} = useProjectData();
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [enuSpace] = useEnuSpaceModule();
    const [ProjectTreeData, setProjectTreeData] = useState(null); //프로젝트 트리 데이터
    const [ttsList, setTtsList] = useState({});
    const [audioRecorderList, setAudioRecorderList] = useState({});

    useAudio(enuSpace); // 오디오 재생
    
    const location = useLocation();
    const BASE_DATA = useMemo(() => {
        return {
            userId: location?.state?.userId,
            projectId: location?.state?.projectId,
            projectName: location?.state?.projectName
        }
    }, [location?.state?.userId, location?.state?.projectId, location?.state?.projectName]);

    const path = '/Repository/'+ BASE_DATA.userId +'/'+ BASE_DATA.projectId + '/'+BASE_DATA.projectName +'.enup';
    useLoadProject(enuSpace, location.state && path, true, isTemplate, location.state?.templateJson, location.state?.tempImageInfo);
    
    useEffect(()=>{
        //FIXME: 231203 sessionStorage 에 저장하는 projectId 위치를 프로젝트 로딩하면서 저장하도록 변경 확인 필요 편집작업시 계속 login하는 문제가 해결되는게 검증되면 삭제처리 
        localStorage.setItem('projectId', BASE_DATA.projectId);  
        localStorage.removeItem('publishId');
        
        dispatch(setBaseData(BASE_DATA));
    }, [BASE_DATA, dispatch]);

    // 프로젝트 정보를 받아서 Explorer 창 업데이트 및 첫 픽처 페이지 오픈
    window.Re_UpdateProjectCtrl = (_msg) => {
        console.log(`%c Re_UpdateProjectCtrl---`, 'color: blue');
        const {type, data: projectTreeData} = JSON.parse(_msg);
        if(type === 'success'){
            dispatch(setTreeData(projectTreeData));
            
            if(!isTemplate)
                setIsLoadingPage(false);
            else
                setProjectTreeData(projectTreeData);
        }else if(type === 'failed'){
            
        }
    }

    window.Re_SetView_Edit = () =>{
        if(!isTemplate) return

        const ActivateViewPath = enuSpace.GetActivateView();
        ProjectSave(enuSpace, ActivateViewPath, ProjectTreeData, 'save');
        const {userId, projectId, projectName} = BASE_DATA;
        navigate('/MyRepository/editMode', { state: { userId, projectId , projectName }, replace: true});
    }

    useEffect(() => {
        dispatch(InitProjectData());
        dispatch(deleteTreeData());

        return () => {
            dispatch(AllClearModalPopup());
            dispatch(showRightMenu({
                type: null, top: null, left: null, data: {}
            }));
            dispatch(AllClearCanvasView());
        };
    }, [dispatch]);

    window.Re_TextToSpeech = async (id, speaker, text, volume, speed, pitch) => {
        const url = "https://edu.enuspace.com/api/chatbot/tts";
        const response = await fetch(
          url,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer no-token`,
            },
            cache: "no-cache",
            body: JSON.stringify({ speaker, speed, volume, pitch, text }),
          }
        );
        const data = await response.blob();
        const audioUrl = URL.createObjectURL(data);
        const audio = new Audio(audioUrl);
        audio.onended = () => {
            setTtsList((prev) => {
            const updatedTtsList = { ...prev };
            delete updatedTtsList[id];
            return updatedTtsList;
            });
        };
        audio.play();
        setTtsList((prev) => ({ ...prev, [id]: audio }));
      };

    window.Re_StopTextToSpeech = (id) => {
        const audio = ttsList[id];
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          setTtsList((prev) => {
            const updatedTtsList = { ...prev };
            delete updatedTtsList[id];
            return updatedTtsList;
            });
        } else {
          console.error(`No active TTS found for id: ${id}`);
        }
      };

    window.Re_QueryToChatbot = async (uuid, ptr, func, query, isChunked) => {
        const enuSpace = window.g_enuSpace;
        const pointer = enuSpace.GetClassPointer(ptr);
      const response = await fetch(
        "https://edu.enuspace.com/api/chatbot/query",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer no-token`,
          },
          cache: "no-cache",
          body: JSON.stringify({ uuid, query }),
        }
      );
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        let flag = false;
        chunk.split("\n").forEach((line) => {
            if (line.startsWith("event:")) {
              if(isChunked){
                if (line.substring(6).trim() === "token") {
                  flag = true;
              }
              } else{
                if (line.substring(6).trim() === "result") {
                  flag = true;
                }
              }
            } else if (flag && line.startsWith("data:")) {
                const message = JSON.parse(line.substring(5));
                enuSpace.RunCallback(pointer, message.message.content, func, 99);
                flag = false;
            }
        });
      }
    };

    window.Re_SpeechToTextStart = async (id, lang, ptr, func) => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          console.error("Media devices not supported in this browser.");
          return;
      }
      if (!(lang === "Kor" || lang === "Eng" || lang === "Jpn" || lang === "Chn")) return;
      const pointer = enuSpace.GetClassPointer(ptr);
  
      try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          const mediaRecorder = new MediaRecorder(stream);
          const audioChunks = [];
          let startTime;
  
          mediaRecorder.ondataavailable = (event) => {
              audioChunks.push(event.data);
          };

          mediaRecorder.onstart = () => {
              startTime = Date.now();
          };
  
          mediaRecorder.onstop = async () => {
              const endTime = Date.now();
              const duration = (endTime - startTime) / 1000;
              let msg = "";
              if (duration > 60) {
                msg = { message: { content: "The recording time is too long. Please record within 1 minute." } };
              } else {
                const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                const url = "https://edu.enuspace.com/api/chatbot/stt?lang=" + lang;
  
                const response = await fetch(
                  url,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "audio/wav",
                      "Authorization": `Bearer no-token`,
                    },
                    cache: "no-cache",
                    body: audioBlob
                  }
                );
                msg = await response.text();
                msg = JSON.parse(msg);
                msg = msg.text;

                if (!response.ok) {
                  msg = { message: { content: "Failed to transcribe audio." } };
                }
              }
              enuSpace.RunCallback(pointer, msg, func, 99);
          };
  
          mediaRecorder.start();
          setAudioRecorderList((prev) => ({ ...prev, [id]: mediaRecorder }));
          console.log(`Recording started for id: ${id}`);
      } catch (err) {
          console.error("Error accessing media devices:", err);
      }
  };
  
  window.Re_SpeechToTextEnd = (id) => {
      const mediaRecorder = audioRecorderList[id];
      if (mediaRecorder) {
          mediaRecorder.stop();
          setAudioRecorderList((prev) => {
            const updatedAudioList = { ...prev };
            delete updatedAudioList[id];
            return updatedAudioList;
            });
          console.log(`Recording stopped for id: ${id}`);
      } else {
          console.error(`No active recording found for id: ${id}`);
      }
  };

    const {SetGlobalValues, RefreshAccessToken, ShowLink, EditModeShowMarkdown, ShowDocumentModal, cursorType} = useWindowFun(enuSpace, setIsLoadingPage);
    window.Re_SetGlobalValues = SetGlobalValues;
    window.Re_RefreshAccessToken = RefreshAccessToken;
    window.Re_ShowLink = ShowLink;
    window.Re_ShowMarkdown = EditModeShowMarkdown;
    window.Re_ShowDocument = ShowDocumentModal;

    return(
        <>
            <LoadingLayer marginTop={"30px"} isLoadingPage={isLoadingPage}/>
            <ProjectPageContent enuSpace={enuSpace} style={{cursor: cursorType}}/>
            <EnuFileModule enuSpace={enuSpace}/>
        </>
    )
}

export default EditModePage;