
// import {useEffect, useState} from 'react';
import { Layout } from 'digitalTwin/common';
import { useNavigate, Link } from 'react-router-dom';
import styled from "styled-components";
import { MdOutlineLogout, MdManageAccounts } from "react-icons/md";
import useMember from 'hooks/useMember';

const NavItems = [
    { path: '/DB', label: 'Database' },
    { path: '/Canvas', label: 'Canvas' },
    { path: '/Archive', label: 'Archive' },
    { path: '/Board', label: 'Board' },
    { path: '/MyRepository', label: 'Repository' },
];

function BioHealthHeader() {
    const { Logout } = useMember();
    const nickname = localStorage.getItem('nickname');
    const profileId = localStorage.getItem('profileId');

    const navigate = useNavigate();

    return (
        <HeaderContent className='Nav_Header_fixed'>
            <HeaderLayout>
                <div className="Nav_Header_content">
                    <div className="Nav_title">
                        <img src='bioHealth\img\logo.avif' alt='logo' className='Nav_logo' onClick={() => navigate('/biohealth/intro')} />
                    </div>
                    <NavContent>
                        {NavItems.map((item) => {
                            const { label, path } = item;
                            // const ClassName = currentUrl === path ? 'SelectedLink' : '';
                            return (
                                <div className='Nav_normal' key={label}>
                                    <Link to={path} className={'SelectedLink'} target="_blank">{label}</Link>
                                </div>
                            )
                        })}
                        <MyMenu>
                            <span>{nickname}님</span>
                            <img id='HeaderPreview' src={`/profile/${profileId}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                            <div id='myMenu_normal'>
                                <ul>
                                    <li onClick={(e) => navigate('/MyPage')}>
                                        <MdManageAccounts style={{ 'color': 'steelblue' }} />My Page
                                    </li>
                                    <li onClick={Logout}>
                                        <MdOutlineLogout style={{ 'color': 'steelblue' }} />Logout
                                    </li>
                                </ul>
                            </div>
                        </MyMenu>
                    </NavContent>
                </div>
            </HeaderLayout>
        </HeaderContent>
    )
}
export default BioHealthHeader;

//스타일-------------------------------------
const HeaderContent = styled.header`
    padding-top: 7px;
    position: relative;
    top: 0;
    height: 72px;
    width: 100%;
    min-width: 480px;
    display: flex;
    flex-direction: column-reverse;
    z-index: 9;
    border-bottom: 1px solid ${({ theme }) => theme.border_color_Gray};

    &.Nav_Header_fixed{
        background-color: none;
    
        a, button.btn_logout{
            color: ${({ theme }) => theme.font_color_Black};
        }

        .partner_logo{
            &::after{
                background-color: ${({ theme }) => theme.background_color_DarkGray};
            }
        }
    
        .btn_login, .btn_logout {
            border-color: ${({ theme }) => theme.border_color_Blue};
            background-color: transparent;
    
            &:hover {
                background-color: ${({ theme }) => theme.background_color_LightBlue};
            }
        }
    }

    .Nav_Header_content{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a{
            text-decoration: none;
        }

        .Nav_Title{
            font-size: 1.5rem;
            text-align: center;
            color: ${({ theme }) => theme.font_color_Black};
            font-weight: bold;
            line-height: 30px;
        }

        .Nav_logo{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 240px;
            height: 40px;
        }

        .Nav_nav{
            display: flex;
            align-items: center;

            ul>a{
                margin-right: 32px;
            }

            &>button{
                border-style: solid;
                border-width: 1px;
                cursor: pointer;
            }

            .btn_login {
                border-radius: 4px;
                box-sizing: border-box;
                padding: 0;

                &>a {
                    display: block;
                    padding: 8px 16px;
                    font-size: 1rem;
                }
            }

            .btn_logout {
                border-radius: 4px;
                box-sizing: border-box;
                display: block;
                padding: 8px 16px;
                font-size: 1rem;
            }
        }
    }

    .Nav_Login_content
    {
        display: flex;
        gap: 8px;
        font-size: 1rem;
            line-height: 1.4rem;
            white-space: pre-wrap;
            text-align: center;
            font-weight: bold;
            color: ${({ theme }) => theme.font_color_Gray};
            opacity: 0.6;
            cursor: pointer;
    }
`;

const HeaderLayout = styled(Layout)`
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
`;

const NavContent = styled.nav`
    display: flex;
    align-items: center;
    gap: 24px;
    height: 42px;

    span{
        padding-right: 8px;
        display: inline-block;
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.6;
        height: 100%;
        display: flex;
        align-items: center;
    }
`;

const MyMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;

    &:hover>#myMenu_normal{ display: block; }

    #myMenu_normal{
        display: none;
        position: absolute;
        top: 40px;
        width: 110px;
        height: 100px;

        ul{
            position: absolute;
            top: 18px;
            left: 0;
            width: 110px;
            padding: 4px;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
            overflow: hidden;
            z-index:9;
            box-sizing: border-box;

            &>li{
                display: flex;
                width: 100%;
                padding: 8px;
                color: ${({ theme }) => theme.font_color_Black};
                box-sizing: border-box;

                &:hover{
                    background-color: ${({ theme }) => theme.background_color_LightBlue};
                }

                &>svg{
                    font-size: 1.2rem;
                    padding-right: 4px;
                }
            }
        }
    }

    &>img{
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        box-sizing: border-box;
        overflow: hidden;
        object-fit: cover;
        background-color: #fff;
        border-color: ${({ isFixedHeader, theme }) => isFixedHeader ? theme.border_color_Gray : '#FFF'};
    }
`;