import CardMenuBtn from 'biohealth/components/atom/CardMenuBtn'

import styled from "styled-components";

const menuInfoList = [
    {
        label: '의사',
        url: 'https://www.enuspace.com/Publish/link?61a804e3f2f64127a1a2b1cf04366351&Bio_Template_1',
    },
    {
        label: '환자',
        url: 'https://www.enuspace.com/Publish/link?61a804e3f2f64127a1a2b1cf04366351&Bio_Template_1',
    }
]

export default function SelectTarget() {

    return (
        <SelectTargetContainer>
            <TypeBox>
                {menuInfoList.map((info, index) => {
                    const { label, url } = info;
                    const bgImg = 'biohealth/img/hospital.jpg';
                    const clickEvent = () => url ? window.location.href = url : alert('준비중입니다.');

                    return (
                        <CardMenuBtn
                            key={index}
                            label={label}
                            bgImg={bgImg}
                            callBackEvent={clickEvent}
                        />
                    )
                })}
            </TypeBox>
        </SelectTargetContainer>
    )
}

const SelectTargetContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 80px);
`;

const TypeBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-inline: 90px;
`;