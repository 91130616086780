import MainPage from 'components/pages/MainPage';
import LoginPage from 'components/pages/LoginPage';
import ResetPassword from 'components/pages/ResetPassword';
import Repository from 'components/pages/Repository';
import RuntimeModePage from 'components/pages/RuntimeModePage';
import ErrorPage from 'components/common/ErrorPage';
import EditModePage from 'components/pages/EditModePage';
import LinkView from 'components/pages/LinkView';
import LinkCanvas from 'components/pages/LinkCanvas';
import ManualPage from 'components/pages/ManualPage';
import DB from 'components/pages/DB';
import DBTag from 'components/pages/DBTag';
import DocumentPage from 'components/pages/DocumentPage';
import MyPage from 'components/pages/MyPage';
import GroupsPage from 'components/pages/GroupsPage';
import TernsOfService from 'components/pages/TernsOfService';
import CanvasRepository from 'components/pages/CanvasRepository';
import RunEnuCanvas from 'components/pages/RunEnuCanvas';
import Project3D from 'components/pages/Project3D';
import BoardRepository from 'components/pages/BoardRepository';
import BoardSettingPage from 'components/pages/BoardSettingPage';
import ArchiveRepository from 'components/pages/ArchiveRepository';
import ArchiveSettingPage from 'components/pages/ArchiveSettingPage';
import LinkBoard from 'components/pages/LinkBoard';
import LinkArchive from 'components/pages/LinkArchive';
import SignUpPage from 'components/pages/SignUpPage';

const AppRoutes = [
  {
    index: true,
    element: <MainPage />,
    isCheckToken : false
  },
  {
    path: '/login',
    element: <LoginPage />,
    isCheckToken : false
  },
  {
    path: '/signUp',
    element: <SignUpPage />,
    isCheckToken : false
  },
  {
    path: '/ResetPassword',
    element: <ResetPassword />,
    isCheckToken : false
  },
  {
    path: '/MyRepository',
    element: <Repository />,
    isCheckToken : true
  },
  {
    path: '/MyPage',
    element: <MyPage />,
    isCheckToken : true
  },
  {
    path: '/Groups',
    element: <GroupsPage />,
    isCheckToken : true
  },
  {
    path: '/DB',
    element: <DB />,
    isCheckToken : true
  },
  {
    path: '/DB/Tag',
    element: <DBTag />,
    isCheckToken : true
  },
  {
    path: '/MyRepository/editMode',
    element: <EditModePage/>,
    isCheckToken : true
  },
  {
    path: '/MyRepository/templateMode',
    element: <EditModePage isTemplate={true}/>,
    isCheckToken : true
  },
  {
    path: '/MyRepository/editMode3D',
    element: <Project3D />,
    isCheckToken : true
  },
  {
    path: '/MyRepository/runtimeMode',
    element: <RuntimeModePage />,
    isCheckToken : true
  },
  {
    path: '/Publish/runtimeMode',
    element: <RuntimeModePage />,
    isCheckToken : false
  },
  {
    path: '/Publish/link',
    element: <LinkView />,
    isCheckToken : false
  },
  {
    path: '/Canvas/link',
    element: <LinkCanvas />,
    isCheckToken : false
  },
  {
    path: '/EnuspaceDemo',
    element: <RuntimeModePage />,
    isCheckToken : false
  },
  {
    path: '/EnuspaceDemoWithChat',
    element: <RuntimeModePage />,
    isCheckToken : false
  },
  {
    path: '/UserGuide',
    element: <ManualPage />,
    isCheckToken : false
  },
  {
    path: '/MyRepository/DocumentViewer/',
    element: <DocumentPage/>,
    isCheckToken : true
  },
  {
    path: '/Publish/DocumentViewer',
    element: <DocumentPage/>,
    isCheckToken : false
  },
  {
    path: '/terms-of-service/',
    element: <TernsOfService/>,
    isCheckToken : false
  },
  {
    path: '/Canvas',
    element: <CanvasRepository/>,
    isCheckToken : true
  },
  {
    path: '/RunEnuCanvas',
    element: <RunEnuCanvas/>,
    isCheckToken : false
  },
  {
    path: '/Board',
    element: <BoardRepository/>,
    isCheckToken : true
  },
  {
    path: '/Board/link',
    element: <LinkBoard/>,
    isCheckToken : false
  },
  {
    path: '/Board/Settings',
    element: <BoardSettingPage/>,
    isCheckToken : true
  },
  {
    path: '/Archive',
    element: <ArchiveRepository/>,
    isCheckToken : true
  },
  {
    path: '/Archive/link',
    element: <LinkArchive/>,
    isCheckToken : false
  },
  {
    path: '/Archive/Settings',
    element: <ArchiveSettingPage/>,
    isCheckToken : true
  },
  {
    path: '/*',
    element: <ErrorPage />,
    isCheckToken : false
  },
];

export default AppRoutes;