import {useState ,useContext, useCallback, useEffect, useReducer} from 'react'

import BoardCommon from 'biohealth/components/molecule/BoardCommon'
import {FormatFileSize} from 'components/Utility'
import {BOARD_ID} from 'biohealth/context/bioBoardContext'
import {useWebSocketFunction} from 'hooks/useWebSocketControl'
import {BtnSecondary} from "components/atom/Button";
import {GetBoardPost} from 'api/ArchiveApi'

import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';

function fileReducer(state, action){
    switch(action.type){
        case 'clear':{
            return {Temp: null, Pool:[], List:{}, Size:0}
        }
        case 'uploadReady':{
            return {...state, Temp: {...action.temp}, Pool:[...action.pool]}
        }
        case 'removePool':{
            const Pool = state.Pool.filter(item => item.name !== action.fileName);
            return {...state, Pool}
        }
        case 'initTemp':{
            return {...state, Temp: null}
        }
        case 'checkedFail':{
            for (const key in state.Temp) {
                if (state.Temp[key].name === action.fileName) {
                    state.Temp[key].state = 'fail';
                    break;
                }
            }
            return {...state}
        }
        case 'addList':{
            let Size = 0;
            const addUploadFile = {};
            let startIndx = Object.keys(state.List).length;

            Object.entries(state.Temp).forEach(([key, file]) => {
                if (file?.state === 'fail') {
                    return;
                }
                addUploadFile[startIndx++] = file;
                Size += file.size;
            });
        
            Object.values(state.List).forEach(file => {
                Size += file.size;
            });
        
            return { ...state, Temp: null, List: { ...state.List, ...addUploadFile }, Size };
        }
        case 'deleteTemp':{
            delete state.Temp[action.index]
            return { ...state };
        }
        case 'deleteList':{
            const Size = state.Size - state.List[action.index].size;
            delete state.List[action.index];
            return {...state,  Size}
        }
        case 'setSize':{
            return {...state,  Size: action.size}
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`); 
    }
}

export default function BioBoardDetail(){
    const { postIdx } = useParams();
    const navigate = useNavigate();
    const boardId = useContext(BOARD_ID);
    const userSystemId = localStorage.getItem('systemId');

    const [boardInfo, setBoardInfo] = useState({});
    const [TempBlobData, setTempBlobData] = useState([]);
    const { ws, send, onMessage, ConnectSock, CloseSock } = useWebSocketFunction(`wss://${document.location.hostname}:${document.location.port}/BoardFiles`);
    const [FileData, setFileData] = useReducer(fileReducer, {Temp: null, Pool:[], List:{}, Size:0})

    const GetBoardInfo = useCallback((postIdx)=>{
        if(!boardId || !userSystemId) return;

        GetBoardPost(boardId, userSystemId, postIdx)
            .then(res => {
                const {result, data} = res.data
                if(result === 'Success'){
                    setBoardInfo(data);
                }
            })
    }, [boardId, userSystemId]);

    useEffect(()=>{
        if(!postIdx) return;
        
        GetBoardInfo(postIdx);
    }, [postIdx, GetBoardInfo]);

    const DownLoadFile = useCallback((TargetTempBlobData)=>{
        const {STATE, FILE_IDX, DATA, FILE_NAME, FILE_EXTENSION} = TargetTempBlobData;
        if(STATE === 'Downloading') return;

        if(STATE === "Pending"){
            ConnectSock({
                MESSAGE: 'DownloadFile',
                DATA: {
                    ARCHIVE_SYSTEMID: boardId,
                    MEMBER_SYSTEMID: userSystemId,
                    POST_IDX: Number(postIdx),
                    FILE_IDX,
                }
            });

            setTempBlobData(prev => {
                return prev.map(item => {
                    if (item.FILE_IDX === FILE_IDX) {
                        return { ...item, STATE: 'Downloading' };
                    }
                    return item;
                });
            });
        }else if(STATE === "Completed"){
            const targetFileName = `${FILE_NAME}${FILE_EXTENSION}`;
            const url = window.URL.createObjectURL(new Blob(DATA));
            const a = document.createElement('a');
            a.href = url;
            a.download = targetFileName;
            document.body.appendChild(a);
            a.click();
    
            window.URL.revokeObjectURL(url);
            CloseSock();

            setTempBlobData(prev =>{
                return prev.filter(item => `${item.FILE_NAME}${item.FILE_EXTENSION}` !== targetFileName)
            })
        }

    }, [boardId, userSystemId, postIdx, ConnectSock, CloseSock]);

    useEffect(() => {
        onMessage(msg => {
            const { data } = msg;

            if (typeof (data) === "string") {
                const { TYPE, Percent, Result, File_Name, Msg } = JSON.parse(data);

                if (TYPE === 'Res_UploadFileTemp') {
                    // 파일 업로드
                    if (Result === "Processing") {
                        document.getElementById(File_Name).value = Percent;
                        return;
                    }

                    setFileData({type:'removePool', fileName:File_Name})

                    if (Result === 'Fail') {
                        setFileData({type:'checkedFail', fileName:File_Name})
                        const targetElement = document.getElementById(File_Name);
                        const parentElement = targetElement.parentNode;
                        targetElement.remove();
                        const spanElement = document.createElement('span');
                        spanElement.textContent = Msg;
                        parentElement.appendChild(spanElement);
                    }

                } else if (TYPE === 'Res_DownloadFile') {
                    // 파일다운로드
                    if (Result === "Success") {
                        setTempBlobData(prev => {
                            return prev.map(item => {
                                const { FILE_NAME, FILE_EXTENSION } = item;
                                if (`${FILE_NAME}${FILE_EXTENSION}` === File_Name) {
                                    return { ...item, STATE: 'Completed' };
                                } else
                                    return item;
                            })
                        })
                    } else if (Result === "File") {
                        // TODO: 실패시 로직 추후 진행
                    }
                }
            } else {
                // 문자가 아닌 것은 데이터 정보
                setTempBlobData(prev => {
                    return prev.map(item => {
                        if (item.STATE === 'Downloading') {
                            return { ...item, DATA: [...item.DATA, data] };
                        }
                        return item;
                    });
                });
            }
        })
    }, [onMessage]);

    const SocketUpload = useCallback((fileInfo) => {
        const { name: FILE_NAME, size: FILE_SIZE } = fileInfo;
        send({
            MESSAGE: 'UploadFileTemp',
            DATA: {
                ARCHIVE_SYSTEMID: boardId,
                MEMBER_SYSTEMID: userSystemId,
                TEMP_SYSTEMID: boardInfo.TEMP_SYSTEMID,
                FILE_NAME,
                FILE_SIZE,
            }
        });

        const reader = new FileReader();
        reader.onload = function (event) {
            const data = event.target.result;
            ws?.send(data);
        };
        reader.readAsArrayBuffer(fileInfo);
    }, [boardInfo.TEMP_SYSTEMID, boardId, userSystemId, send, ws]);

    useEffect(()=>{
        if(!TempBlobData[0]) return;
        console.log("TempBlobData-", TempBlobData)
        DownLoadFile(TempBlobData[0]);
    }, [TempBlobData, DownLoadFile]);    useEffect(()=>{
        if (FileData.Pool[0]) {
            SocketUpload(FileData.Pool[0]);
        }
    }, [FileData.Pool, SocketUpload]);

    useEffect(()=>{
        if (FileData.Pool[0]) {
            SocketUpload(FileData.Pool[0]);
        }
    }, [FileData.Pool, SocketUpload]);

    return(
        <BoardCommon>
            <ContentInfo className='noticeBody'>
                <div>
                    {boardInfo.TITLE}
                </div>
                <div id='createdInfo'>
                    <div>
                        <span>작성자</span>
                        {boardInfo.NICKNAME}
                    </div>
                    <div>
                        <span>작성일</span>
                        {boardInfo.CREATED_STAMP?.replace(/-/g, '.').replace(/T/g, '  ')}
                    </div>
                </div>
                <MainInfo id='info' isFileList={boardInfo?.FILE_LIST === null ? false : true}>
                    <textarea value={boardInfo.CONTENT} disabled={false} readonly/>
                    <div id='fileBox'>
                        {boardInfo?.FILE_LIST &&
                            <>
                                <p>첨부 파일 ------------------------------- </p>
                                <ul>
                                    {
                                        boardInfo.FILE_LIST.map(info => {
                                            const { FILE_IDX, FILE_NAME, FILE_EXTENSION, FILE_SIZE } = info;
                                            return (
                                                <li 
                                                    key={FILE_IDX}
                                                    onClick={(e)=>{
                                                        setTempBlobData(prev => ([...prev, {FILE_NAME, FILE_EXTENSION, FILE_SIZE, FILE_IDX, STATE:'Pending', DATA:[]}]))
                                                    }}
                                                >
                                                    <span className='fileName'>{FILE_NAME + FILE_EXTENSION}</span>
                                                    <span className='fileSize'>({FormatFileSize(FILE_SIZE)})</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </>
                        }
                    </div>
                </MainInfo>
                
            </ContentInfo>
            <NoticeBottom>
                <div>
                    <BtnSecondary 
                        label='Cancel'
                        onClick={(e)=>navigate(-1)}
                    />
                </div>
            </NoticeBottom>
        </BoardCommon>
    )
}

const NoticeBottom = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-end; 

    &>div{
        height: 48px;

        button{
            height: 100%;
        }
    }
`;

const ContentInfo = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 1.4rem;

    &>div:not(#info){
        display: flex;
        align-items: center;
        height: 56px;
        padding-inline: 8px;
        border-bottom: 1px solid  ${({theme}) => theme.border_color_Gray};
        gap: 32px;

        &#createdInfo{
            padding-inline: 0px;

            &>div{
                position: relative;
                height: 100%;
                flex-grow: 1;
                display: flex;
                align-items: center;

                span{
                    padding-inline: 16px;
                    margin-right: 16px;
                    height: 100%;
                    background-color: ${({theme}) => theme.background_color_PaleGray};
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
`;

const MainInfo = styled.div`
    &#info{
        position: relative;
        width: 100%;
        flex-grow: 1;
    }
    
    textarea{
        position: relative;
        width: 100%;
        height: ${({isFileList})=> isFileList ? 'calc(100% - 158px)' : '100%'}; 
        padding: 8px;
        box-sizing: border-box;
        font-size: 1.4rem;
        border: 0 none;
        resize: none;

        &:focus-visible{
            outline: 0 none;
        }
    }

    #fileBox{
        &>p{
            font-size: 1.2rem;
            opacity: .6;
            margin-bottom: 16px;
        }

        ul{
            position: relative;
            overflow: auto;
            height: 120px;
            padding-left: 16px;
            li{
                font-size: 1.2rem;
                margin-bottom: 16px;
                cursor: pointer;

                &:hover{
                    text-decoration: underline;
                }

                .fileSize{
                    margin-left: 8px;
                    opacity: .6;
                }
            }
        }
    }
`;