import {useState, useRef} from 'react';

import useModalPopup from 'hooks/useModalPopup';
import {BgPopup, ModalBottomBox} from 'components/css/common';
import Modal from 'components/modal_new/Modal';
import {SubTitle} from "components/css/common"

import moment from 'moment';
import 'moment-timezone';
import { useSelector } from 'react-redux';
import ModalContent from "./ModalContent";
import  styled from "styled-components";
import DatePicker from "react-datepicker";
import {BtnPrimary} from "components/atom/Button";
import useSQLite from 'hooks/useSQLite';

function ModalSpace({enuSpace}){
    const ModalList = useSelector(state => state.modalPopup);
    const {SQLiteFileList} = useSQLite('');
    const [Calendar, setCalendar] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const {AddModal, UpdateModalData, DeleteModal} = useModalPopup();
    const {userId: MEMBER_SYSTEMID } = useSelector(state => state.projectData.BASE_DATA);

    const [LuaTextFileFun, setLuaTextFileFun] = useState();
    const InputFile = useRef(null);
    const [FileData, setFileData] = useState('');

    window.Re_Calendar = (_type, _unit, _callback, _node, x, y) => {
        const element = document.getElementById('Canvas_box');
        const rect = element.getBoundingClientRect();
        const {x:rectX, y:rectY} = rect;
        setCalendar({_type, _unit, _callback: String(_callback), _node, x:(x+rectX-136), y:(y+rectY)});
    }

    const HandlerCalender = (e) => {
        const SetData = new Date(startDate);
        // 년, 월, 일 추출
        const year = SetData.getFullYear();
        const month = String(SetData.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열 형태로 변환
        const day = String(SetData.getDate()).padStart(2, '0'); // 날짜를 문자열로 변환하고 두 자리로 만듦
        // YYYY-MM-DD 형식으로 조합
        const formattedDate = `${year}-${month}-${day}`;

        enuSpace.RunCallback(Calendar._node , formattedDate, String(Calendar._callback), 0);
        setCalendar();
    }

    window.Re_PopupInterfaceDB = () => {
        AddModal('PopupInterfaceDB','InterfaceDBList','PopupInterfaceDB', {uhu:55})
    }

    window.Re_PopupChartDynamic = (CHARTID, TIME, DURATION, strDBName, strTagName) => {
        const data = [];
        const parsedDBList = JSON.parse(strDBName);
        const parsedTagList = JSON.parse(strTagName);
        parsedDBList.forEach((DB_TABLE_ID, index) => {
            data.push({
                MEMBER_SYSTEMID,
                DB_TABLE_ID,
                TAG: parsedTagList[index],
                START_TIME: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                TIME,
                DURATION,
                TYPE: 'DYNAMIC',
                CHARTID,
            });
        })
        DeleteModal(CHARTID)
        AddModal(CHARTID,`Chart`,"Chart", data);
    }

    window.Re_PopupChartStatic = (CHARTID, DATA, CHART_TYPE) => {
        const data = [{
            MEMBER_SYSTEMID,
            TYPE: 'STATIC',
            DATA,
            CHART_TYPE,
            CHARTID,
        }];
        DeleteModal(CHARTID)
        AddModal( CHARTID,`Chart`,"Chart", data);
    }

    window.Re_PopupTable = (DATA) => {
        const data = [{
            MEMBER_SYSTEMID,
            DATA,
        }];
        AddModal("table",`Table`,"Table", data);
    }

    window.Re_AddChartSeriesDynamic = (chartID, strDBName, strTagName) => {
        const findModalIndex = ModalList.findIndex(item => item.id === chartID);

        //모달이 없을 경우
        if(findModalIndex === -1){
            return window.AddAlertMsg({type:'error', msg:'차트를 먼저 생성해주세요'});
        }

        const data = [];
        let start_time;
        let time;
        let duration;

        const targetModalData = ModalList[findModalIndex].data;
        const dataArray = Array.isArray(targetModalData) ? targetModalData : Object.values(targetModalData);

        dataArray.map((item) => {
            let res = {};
            if (item.TYPE === 'DYNAMIC') {
                start_time = item.START_TIME;
                time = item.TIME;
                duration = item.DURATION;

                res.DB_TABLE_ID     = item.DB_TABLE_ID;
                res.DURATION        = item.DURATION;
                res.MEMBER_SYSTEMID = MEMBER_SYSTEMID;
                res.START_TIME      = item.START_TIME;
                res.TAG             = item.TAG;
                res.TIME            = item.TIME;
            }
            else if (item.TYPE === 'STATIC') {
                res.CHART_TYPE      = item.CHART_TYPE;
                res.DATA            = item.DATA;
                res.MEMBER_SYSTEMID = item.MEMBER_SYSTEMID;
            }
            
            res.TYPE = item.TYPE;
            res.CHARTID = chartID;

            data.push(res);
            return res; 
        });

        const parsedDBList = JSON.parse(strDBName);
        const parsedTagList = JSON.parse(strTagName);

        parsedDBList.forEach((DB_TABLE_ID, index) => {
            data.push({
                DB_TABLE_ID,
                DURATION: duration,
                MEMBER_SYSTEMID,
                START_TIME: start_time,
                TAG: parsedTagList[index],
                TIME: time,
                TYPE: 'DYNAMIC',
                CHARTID: chartID,
            });
        })

        const filteredData = data.filter((item, index, self) => {
            return (
                index === self.findIndex(
                    (t) => t.DB_TABLE_ID === item.DB_TABLE_ID && t.TAG === item.TAG
                )
            );
        });
        UpdateModalData(chartID, filteredData)
    }

    window.Re_AddChartSeriesStatic = (chartID, ChartData, chartType) => {
        const findModalIndex = ModalList.findIndex(item => item.id === chartID);
        //모달이 없을 경우
        if(findModalIndex === -1){
            return window.AddAlertMsg({type:'error', msg:'차트를 먼저 생성해주세요'});
        }

        const data = [];
        const targetModalData = ModalList[findModalIndex].data;
        const dataArray = Array.isArray(targetModalData) ? targetModalData : Object.values(targetModalData);
        
        dataArray.map((item) => {
            let res = {};
            if (item.TYPE === 'DYNAMIC') {
                res.DB_TABLE_ID     = item.DB_TABLE_ID;
                res.DURATION        = item.DURATION;
                res.MEMBER_SYSTEMID = MEMBER_SYSTEMID;
                res.START_TIME      = item.START_TIME;
                res.TAG             = item.TAG;
                res.TIME            = item.TIME;
            }
            else if (item.TYPE === 'STATIC') {
                res.CHART_TYPE      = item.CHART_TYPE;
                res.DATA            = item.DATA;
                res.MEMBER_SYSTEMID = item.MEMBER_SYSTEMID;
            }

            res.TYPE = item.TYPE;
            res.CHARTID = chartID;

            data.push(res);
            return res;
        });

        data.push({
            MEMBER_SYSTEMID,
            TYPE: 'STATIC',
            DATA: ChartData,
            CHART_TYPE: chartType,
            CHARTID: chartID,
        });

        UpdateModalData(chartID, data)
    }
    
    // TODO: 함수 위치및 사용 여부 고려
    window.Re_UploadText = (_callback, _node) => {
        console.log(_callback, _node )
        setLuaTextFileFun({_callback, _node})
    }

    // TODO: 함수 위치및 사용 여부 고려
    window.Re_DownloadText  = (_text, _filename, _node) => {
        console.log(_text, _filename, _node )

        const targetFileName = _filename;
        const fileContent = _text;
        const blob = new Blob([fileContent], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = targetFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }
    
    window.Re_PopupSQLite = async (mode) => {
        const data = {
            child: SQLiteFileList.child,
            mode: mode,
        };
    
        if (mode === "edit") {
            AddModal("SQLiteRuntimeModal", "SQLITE editor", "SQLiteRuntimeModal", data);
        } else if (mode === "view") {
            AddModal("SQLiteRuntimeModal", "SQLITE viewer", "SQLiteRuntimeModal", data);
        } else {
            window.alert("PopupSQLite Lua 함수는 인자로 'mode' 혹은 'view'를 작성해야합니다.");
        }

    };
    

    return(
        <>
            {ModalList && 
                ModalList.map((modal, index) => (
                    <ModalContent key={modal.id} _index={index} enuSpace={enuSpace} _Modal={modal}/>
                ))
            }

            {Calendar &&
                <CalenderContainer>
                    <Bg onClick={(e)=>setCalendar()}/>
                    <Modal _x={Calendar.x} _y={Calendar.y} _height={182} _width={274} _minWidth={274} _enableResizing={false} _isHeader={false} __enableResizing={false} _disableDragging={true}>
                        <div>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                inline
                            />
                            <ModalBottomBox>
                                <BtnPrimary label="Set" onClick={HandlerCalender}/>
                            </ModalBottomBox>
                        </div>
                    </Modal>
                </CalenderContainer>
            }

            {LuaTextFileFun &&
                <FileInput>
                    <Bg onClick={(e)=> {
                        setFileData();
                        setLuaTextFileFun();
                        InputFile.current.value = '';
                    }}/>
                    <Modal _height={170} _width={300} _minWidth={274} _enableResizing={false} _isHeader={false} __enableResizing={false} _disableDragging={true}>
                        <SubTitle>upload</SubTitle>
                        <div>
                            <Input 
                                type="file" 
                                id="file" 
                                accept="*" 
                                ref={InputFile} 
                                onChange={(e)=>setFileData(e.target.files)}
                            />

                            <ModalBottomBox>
                                <BtnPrimary label="Upload"
                                    onClick={(e) => {
                                        if (FileData[0] === null) {
                                            return;
                                        }
                                        const fileReader = new FileReader();
                                        if (FileData[0]) {
                                            fileReader.onload = function (event) {
                                                const data = event.target.result;
                                                enuSpace.RunCallback(LuaTextFileFun._node , data, String(LuaTextFileFun._callback), 0);

                                                setFileData();
                                                setLuaTextFileFun();
                                                InputFile.current.value = '';
                                            };

                                            fileReader.readAsText(FileData[0]);
                                        }
                                    }} />
                            </ModalBottomBox>
                        </div>
                    </Modal>
                </FileInput>
            }
        </>
    )
}

export default ModalSpace;

const CalenderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const FileInput = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Input = styled.input`
    border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    flex-grow: 1;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;

    &:hover{
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
    }
`;

const Bg = styled(BgPopup)`
    opacity: 0;
`;
