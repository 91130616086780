import {useState, useRef, useReducer} from "react"

import styled from "styled-components"
import languageEncoding from "detect-file-encoding-and-language";

function reducer(state, action) {
    switch (action.type) {
        case 'init':{
            return {Lines: [],CurrentLine: 0, LineData:''}
        }
        case 'setLines': {
            const Lines = action.text.split('\n')
            const LineData = Lines[state.CurrentLine];
            return {...state, Lines, LineData}
        }
        case 'nextLine': {
            const CurrentLine = state.CurrentLine + 1;
            const LineData = state.Lines[CurrentLine];
            return {...state, CurrentLine, LineData: LineData || null}
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

const init = {
    Lines: [],
    CurrentLine: 0,
    LineData: '',
}

export default function EnuFileModule({enuSpace}){
    const inputRef = useRef(null);
    const [BaseInfo, setBaseInfo] = useState({});
    const [Format, setFormat] = useState('');
    const [Encoding, setEncoding] = useState('');
    const [FileData, setFileData] = useReducer(reducer, init)

    const detectEncoding = async (buffer) => {
        const fileInfo  = await languageEncoding(buffer);
        console.log(fileInfo)
        return fileInfo.encoding;
    };

    const readFile = async (file, encoding) => {
        const reader = new FileReader();
        if(encoding === ''){
            encoding = await detectEncoding(file);
            if(encoding === null || encoding === undefined){
                encoding = 'euc-kr';
            }
        }

        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
            const buffer = e.target.result;
            const text = new TextDecoder(encoding).decode(buffer);
            setFileData({type: 'setLines', text});
        };
    };

    window.Re_OpenFile = (Format, Encoding, funcName, node) => {
        setBaseInfo({funcName, node});
        setEncoding(Encoding);
        inputRef.current.accept= '.' + Format;
        setFormat(Format);
        inputRef.current.value = '';
        inputRef.current.click();
        setFileData({type:'init'});
    }

    window.Re_GetNextLine = (funcName, node) => {
        if(FileData.LineData === null || FileData.LineData === undefined || FileData.LineData === ''){
            enuSpace.RunCallback(node, '', funcName, -1);
            setFileData({type:'init'});
            return;
        }
        enuSpace.RunCallback(node, FileData.LineData, funcName, -1);
        setFileData({type:'nextLine'});
    }

    window.Re_CloseFile = () => setFileData({type:'init'});

    return(
        <Content>
            <input
                type="file"
                ref={inputRef}
                onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    if (selectedFile) {
                        console.log(selectedFile);
                        if(Format === "csv" && selectedFile.type !== "text/csv"){
                            window.AddAlertMsg({type:'error', msg:`csv 파일만 업로드 가능합니다`});
                            return;
                        }else if (Format === "txt" && selectedFile.type !== "text/plain"){
                            window.AddAlertMsg({type:'error', msg:`txt 파일만 업로드 가능합니다`});
                            return;
                        }
                        const {funcName, node} = BaseInfo;    
                        enuSpace.RunCallback(node, selectedFile.name, funcName, -1);
                        readFile(selectedFile, Encoding);
                    } else {
                        window.AddAlertMsg({type:'error', msg:`유효하지 않은 파일입니다.`})
                    }
                }}
            />
        </Content>
    )
}

const Content = styled.div`
    display: none;
`;