import { useState, useEffect } from "react";
import styled from "styled-components";
import { MdOutlineFirstPage, MdOutlineLastPage, MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";

/**
 * 
 * @param {Array} dataList
 * @param {Array} currentPage -현 페이지 넘버링 [value, setValue] useState
 * @param {Object} _setCurrentPosts - 한페이지에 호출될 데이터 [setValue] useState
 */
function Pagination({ _dataList, _currentPage, _setCurrentPosts, amount, ...props }) {
    const ShowPageNumber = 5;   //페이지네이션에 보여줄 페이지 수
    const postsPerPage = amount || 100;   //한 페이지에 보여줄 데이터 수
    const [pageNumber, setPageNumber] = useState([1]);
    const [currentPage, setCurrentPage] = _currentPage;

    useEffect(() => {
        const indexOfLastPost = currentPage * postsPerPage;
        const indexOfFirstPost = indexOfLastPost - postsPerPage;
        _setCurrentPosts(_dataList?.slice(indexOfFirstPost, indexOfLastPost));
    }, [currentPage, _dataList, _setCurrentPosts, postsPerPage]);

    useEffect(() => {
        if (!_dataList) return;

        const totalPosts = _dataList.length;
        const totalPageCount = Math.ceil(totalPosts / postsPerPage);
        setPageNumber([...Array(totalPageCount).keys()].map(i => i + 1));
    }, [_dataList, postsPerPage]);

    const Pagination = ({ _pageNumber }) => {
        const maxPageNumber = _pageNumber[_pageNumber.length - 1];
        const flagNumber = Math.floor(ShowPageNumber / 2);
        let startNumber = Math.max(currentPage - flagNumber, 1);
        let lastNumber = Math.min(currentPage + flagNumber, maxPageNumber);


        if (startNumber === 1) {
            lastNumber = 5;
        } else if (lastNumber === maxPageNumber) {
            startNumber = maxPageNumber - 4;
        }

        return (
            <ul className="pagination">
                {pageNumber.map((pageNum) => {
                    if (pageNum >= startNumber && pageNum <= lastNumber) {
                        return (
                            <li
                                key={pageNum}
                                className={pageNum === currentPage ? 'selectPage' : ''}
                                onClick={() => setCurrentPage(pageNum)}
                            >
                                {pageNum}
                            </li>
                        )
                    }
                    return null;
                })}
            </ul>
        );
    };

    const MovePage = (_type) => {
        switch (_type) {
            case 'FirstPage':
                setCurrentPage(1);
                break;
            case 'PrevPage':
                if (currentPage > 1) {
                    setCurrentPage(prev => prev - 1);
                }
                break;
            case 'NextPage':
                if (currentPage < pageNumber.length) {
                    setCurrentPage(prev => prev + 1);
                }
                break;
            case 'LastPage':
                setCurrentPage(pageNumber.length);
                break;
            default:
                break;
        }
    }

    return (
        <PaginationBar {...props}>
            <span onClick={(e) => MovePage('FirstPage')}><MdOutlineFirstPage /></span>
            <span onClick={(e) => MovePage('PrevPage')}><MdOutlineChevronLeft /></span>
            <Pagination _pageNumber={pageNumber} />
            <span onClick={(e) => MovePage('NextPage')}><MdOutlineChevronRight /></span>
            <span onClick={(e) => MovePage('LastPage')}><MdOutlineLastPage /></span>
        </PaginationBar>
    )


}
export default Pagination;

//스타일-------------------------------------
const PaginationBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    margin-top: 8px;

    span{
        color: ${({ theme }) => theme.base.font_color_DarkGray};
        border-radius: 4px;
        width: 28px;
        height: 28px;
        box-sizing: border-box;
        padding: 6px;
        cursor:pointer;

        &:hover{
            background-color:${({ theme }) => theme.base.background_color_PaleGray};
        }
    }
    
    ul{
        margin: 0 16px;
        display: flex;

        li{
            width:28px;
            height:28px;
            padding: 6px;
            font-size: .9rem;
            color: ${({ theme }) => theme.base.font_color_DarkGray};
            box-sizing: border-box;
            text-align: center;
            cursor:pointer;

            &:hover{
                background-color:${({ theme }) => theme.base.background_color_PaleGray};
            }

            &.selectPage{
                color: ${({ theme }) => theme.base.font_color_DarkBlue};
                background-color:${({ theme }) => theme.base.background_color_LightBlue};
                border-radius: 4px;
            }
        }
    }
`;