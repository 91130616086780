import CardMenuBtn from 'biohealth/components/atom/CardMenuBtn'

import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import IntroNotice from "./IntroNotice";

// const menuInfoList = [
//     {
//         label: '의사/환자',
//         url: '/biohealth/selectTarget',
//         bgImg: 'biohealth/img/hospital.jpg',
//     },
//     {
//         label: '교수/학생',
//         url: null,
//         bgImg: 'biohealth/img/school_2.jpg',
//     }
// ]

const menuInfoList = [
    {
        label: '교육용 메타버스',
        bgImg: 'biohealth/img/school_2.jpg',
        url: "/Publish/link?161af1329706414085d38bfe8aa952b5&DjuMetaverse",
    },
    {
        label: '의료용 메타버스',
        bgImg: 'biohealth/img/hospital.jpg',
        url: "/Publish/link?f4eeb2c041ff4098b07c0f2ec7a4abd6&BioTemplate",
    }
]

function BioHealthMain() {
    const navigate = useNavigate();

    return (
        <MainContainer>
            <TopContainer>
                <h1>대전대학교 바이오헬스 혁신융합대학</h1>
                <h2>바이오헬스 메타버스 플랫폼</h2>
            </TopContainer>
            <BottomMenuContainer>
                {menuInfoList.map((info, index) =>{
                    const {label, url, bgImg} = info;
                    const clickEvent = () => url ? navigate(url) : alert('준비중입니다.');

                    return(
                        <CardMenuBtn 
                            key={index}
                            label={"입장하기"}
                            subLabel={label}
                            bgImg={bgImg}
                            callBackEvent={clickEvent}
                        />
                    )
                })}
            </BottomMenuContainer>
            <NoticeBox>
                <IntroNotice />
            </NoticeBox>
        </MainContainer>
    )
}

export default BioHealthMain;

//스타일----------------------------------
const MainContainer = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const TopContainer = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: normal;

    h1, h2 {
        color: ${({theme}) => theme.font_color_Black};
        animation: fadeIn 2s forwards;
        opacity: 0;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 3.5rem;
        font-weight: bold;
        letter-spacing: 3px;
    }

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }

    @media (min-height: 1090px) { 
        height: 600px; 
    }

    @media (min-height: 1200px) {
        height: 1000px;
    }
`;

const BottomMenuContainer = styled.div`
    width: 1200px;
    height: 400px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border: none;
    border-radius: 1.5rem;
    background: none;
    box-shadow: rgba(117, 117, 117, 0.09) 0px 3px 20px;
`;

const NoticeBox = styled.div`
    width: 100%;
    height: 400px;
`;